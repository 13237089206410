import React, { useEffect, useState } from "react";
import axios from "axios";
import { capacidadCamping } from "../defaults";

import { Paper, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    codigoRes: {
        padding: "0.5rem",
        backgroundColor: "rgb(211,47,47)",
        color: "white",
        display: "flex"

    },
}));



const DisponibilidadCamping = (props) => {
    const classes = useStyles();
    const getCapacidadMaxima = () => {
        return capacidadCamping.filter((sector) => {
            if (sector.sector === props.sector)
                return sector.capacidad
        })
    }
    const [capacidadMaxima, setcapacidadMaxima] = useState(getCapacidadMaxima);
    const [paxReservados, setPaxReservados] = useState(0);
    const [disponiblesL, setDisponiblesL] = useState(0)

    async function paxConReserva() {
        let paxCount = 0;
        let camping = 0;

        if(props.controlCamping){
            camping = 1;
        }
        
        await axios.get(process.env.SERVER_BACKEND_RESERVACIONES + "getDisponibilidadCamping.php", {
            params: {
                fecha: props.fecha,
                fechaSalida: props.fechaSalida,
                sector: encodeURI(props.sector),
                disponibilidad: 1,
                camping: camping
            }
        })
            .then((response) => {
                //.log(response.data)
                paxCount = response.data;
                setPaxReservados(paxCount)
                const newValue = capacidadMaxima[0].capacidad - paxCount - props.paxCount;
                setDisponiblesL(newValue);
                props.onChange(newValue);

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        paxConReserva()
    }, [props.fecha, props.sector, props.paxCount, props.cuposDisponibles, props.fechaSalida])

    useEffect(() => {
        paxConReserva()
    }, [])


    return (
        <>
            {props.controlCamping ?
            <Paper className={classes.codigoRes} >
                <Typography variant={"body2"}>{"Cupos disponibles de acampar"}</Typography>
                <Typography variant="h5">&nbsp;{(props.cuposDisponibles >= 0) ? props.cuposDisponibles : 0}</Typography>
            </Paper>
            :
            
            <div></div>}
        </>


    )
}

export default DisponibilidadCamping

