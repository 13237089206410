import React from "react"
import {checkTokenExpiration, getToken, isLoggedIn} from "../services/auth";
import {navigate} from "gatsby";
import axios from "axios";


const PrivateRoute = ({ component: Component, location, ...rest }) => {


    if (isLoggedIn()){
        //revisar que el token no está expirado.
        setInterval(()=>{
            checkTokenExpiration()
        }, 30 *1000)

        //Al refrescar la pantalla parece no cargar el token inmediatamente, hay que pasarlo entre recargas por el local storage.
        if (!axios.defaults.headers.common['Authorization']){
            axios.defaults.headers.common['Authorization'] = getToken()
        }
        return <Component {...rest} />
    }else{
        navigate("/app/login")
        return null
    }
}

export default PrivateRoute
