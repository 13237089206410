import React from "react";
import {Box, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {produce} from "immer";
import {countries, provincias} from "./paises";
const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "flex"
    },
}));
const Pasajeros = (props) => {
    const classes = useStyles();
    return (
        <>
            {
                props.pasajeros.map((field, index) => {
                    // console.log("renderx")
                    return (
                        <Grid container spacing={4} key={"pax-" + index}>

                            <Box component={Grid} key={field.id} item container spacing={1}>
                                <Grid item xs={12}>
                                    <Box bgcolor={"#e3e3e3"}>
                                        <Typography variant="subtitle1"
                                                    component={"span"}><span>{field.nombrePax}. {field.moneda}{field.costo} por persona</span></Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <TextField name="cantidad" label="Cantidad" required
                                               value={field.cantidad} fullWidth type="number"
                                               InputProps={{inputProps: {min: 0, max: 200}}}
                                               onChange={(e) => {
                                                const cantidad = e.target.value;
                                                props.setPasajeros(currentPasajeros =>
                                                    produce(currentPasajeros, v => {
                                                        v[index].cantidad = parseInt(cantidad);
                                                        if (props.controlCamping) {
                                                            v[index].subtotal = cantidad * v[index].costo * props.diaDeCobro * props.nochesCamping;
                                                        } else {
                                                            v[index].subtotal = cantidad * v[index].costo * props.diaDeCobro;
                                                        }
    
                                                    }))
                                                    
                                            }}
                                    />
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    {(field.nacional != "Costa Rica") ? (
                                                            
                                                            

                                            <FormControl className={classes.formControl}>
                                                <InputLabel
                                                    id="pais-label">Procedencia</InputLabel>
                                                <Select
                                                    labelId="pais-label"
                                                    id="pais"
                                                    value={field.nacional}
                                                    onChange={(e) => {
                                                        const nacional = e.target.value;
                                                        props.setPasajeros(currentPasajeros =>
                                                            produce(currentPasajeros, v => {
                                                                v[index].nacional = nacional;
                                                                if (nacional !== "Costa Rica") {
                                                                    v[index].provincia = "Extranjero";
                                                                }
                                                            }))
                                                    }}
                                                >
                                                    {countries.map((unPais, index) => {
                                                        return (
                                                            <MenuItem key={index}
                                                                      value={unPais.name}>{unPais.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        ) :
                                        (
                                            <FormControl className={classes.formControl}>
                                                <InputLabel
                                                    id="provincia-label">Procedencia</InputLabel>
                                                <Select
                                                    labelId="provincia-label"
                                                    id="provincia"
                                                    value={field.provincia}
                                                    onChange={(e) => {
                                                        const provincia = e.target.value;
                                                        props.setPasajeros(currentPasajeros =>
                                                            produce(currentPasajeros, v => {
                                                                v[index].provincia = provincia;
                                                            }))
                                                    }}
                                                >
                                                    {provincias.map((unaProvincia, index) => {
                                                        return (
                                                            <MenuItem key={index}
                                                                      value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>

                                        )
                                    }


                                </Grid>
                                <Grid item xs={3} md={6}>
                                    <Typography variant={"body1"}> </Typography>
                                </Grid>


                                <Box component={Grid} item xs={2} md={2} textAlign={"right"}>
                                    <Typography
                                        variant={"caption"}>Subtotal</Typography>
                                    <Typography variant={"body2"}>
                                        {field.moneda}{field.subtotal * props.diaDeCobro}
                                    </Typography>

                                </Box>

                            </Box>
                        </Grid>
                    )
                })
            }
        </>
       
    )
}

export default Pasajeros