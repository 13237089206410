import React, {useEffect, useState} from "react";
import axios from "axios";
import {
        makeStyles,
    withStyles,
    FormLabel,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    codigoRes: {
        padding: "0.5rem",
        backgroundColor: "rgb(211,47,47)",
        color: "white",
        
    },
    disponibilidades: {
        display: "flex",
        justifyContent: "space-between"
    },
    tableRightBorder : { border:0, borderLeft:1, borderColor: 'white',borderStyle: 'solid', color: 'white'} // or borderTop: '1px solid red'
    
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(211,47,47)",
        color: "white"
    },
    body: {
        fontSize: 14,
        color: "white"
    },
    td: {
        borderLeft: "1px solid white"
    }
    
}))(TableCell);

const DisponibilidadMarino = (props) => {
    const classes = useStyles();
    const [disponibleSurf, setDisponibleSurf] = useState(0)
    const [disponibleBuceo, setDisponibleBuceo] = useState(0)
 // const [disponiblePaseo, setDisponiblePaseo] = useState(0)
    const [disponibleIsla, setDisponibleIsla] = useState(0)

    async function paxConReserva() {
        
        await axios.get(process.env.GATSBY_REMOTE_SERVER + "getDisponibilidad.php", {
            params: {
                fecha: props.fecha,
                sector: props.sector,
                disponibilidad: 1
            }
        })
            .then((response) => {
                let tmp = {
                    "Surf": 0,
                    "Buceo": 0,
       //             "Paseo recreativo": 0,
                    "Sendero Mirador Isla San José":0
                };
                response.data.forEach((field) => {
                    tmp[field._id] = field.pax;
                })
                const sameActivity = props.actividad == props.editActivity?1:0;
                const sameDate = props.fecha == props.editDate?1:0;
                
                let dSurf = 65 - tmp['Surf'] ;
                let dBuceo = 40 - tmp['Buceo'] ;
                let dIsla = 35 - tmp['Sendero Mirador Isla San José'];
                
                //Si cambian la fecha o actividad liberar los espacios
                if (props.editActivity == 'Surf' && !sameActivity && sameDate)
                    dSurf = dSurf + props.editPax
                if (props.editActivity == 'Buceo' && !sameActivity && sameDate)
                    dBuceo = dBuceo + props.editPax
                if ((props.editActivity == 'Sendero Mirador Isla San José' && !sameActivity && sameDate))
                    dIsla = dIsla + props.editPax
                
                switch (props.actividad){
                    case "Surf":{
                        dSurf = dSurf - props.paxCount + (props.editPax * sameActivity * sameDate);
                        props.onChange(dSurf);
                        break;
                    }
                    case "Buceo":{
                        dBuceo = dBuceo - props.paxCount + (props.editPax * sameActivity * sameDate);
                        props.onChange(dBuceo);
                        break;
                    }
       /*             case "Paseo recreativo":{
                        dPaseo = dPaseo - props.paxCount;
                        props.onChange(dPaseo);
                        break;
                    }*/
                    case "Sendero Mirador Isla San José":{
                       
                        dIsla = dIsla - props.paxCount + (props.editPax * sameActivity * sameDate);
                        
                        props.onChange(dIsla);
                        break;
                    }

                }
                setDisponibleSurf(dSurf);
                setDisponibleBuceo(dBuceo);
              //  setDisponiblePaseo(dPaseo);
                setDisponibleIsla(dIsla);
                
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    
    useEffect(() => {
        paxConReserva()
    }, [props.fecha, props.sector, props.paxCount, props.cuposDisponibles, props.actividad])
    
    useEffect(() => {
        paxConReserva()
    }, [])
    
    return (
        <>
            <FormLabel component="legend">Cupos disponibles:</FormLabel>
            <Table aria-label="simple table" className={classes.codigoRes}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableRightBorder}>Surf</TableCell>
                        <TableCell className={classes.tableRightBorder}>Buceo</TableCell>
                        {/*<TableCell className={classes.tableRightBorder}>Paseos Recreativos</TableCell>*/}
                        <TableCell className={classes.tableRightBorder}>Isla San José</TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                   
                        <TableRow key={"des"} scope="row">
                            <TableCell className={classes.tableRightBorder}>{(disponibleSurf >= 0) ? disponibleSurf : 0}</TableCell>
                            <TableCell className={classes.tableRightBorder}>{(disponibleBuceo >= 0) ? disponibleBuceo : 0}</TableCell>
                            {/*<TableCell className={classes.tableRightBorder}>{(disponiblePaseo >= 0) ? disponiblePaseo : 0}</TableCell>*/}
                            <TableCell className={classes.tableRightBorder}>{(disponibleIsla >= 0) ? disponibleIsla : 0}</TableCell>
                        </TableRow>
                </TableBody>
            </Table>
        </>
    )
}

export default DisponibilidadMarino

