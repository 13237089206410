import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "flex"
    },
}));

const PasajerosCamping = (props) => {
    const classes = useStyles();

    const [cantidadTotalPasajeros, setCantidadTotalPasajeros] = useState(0)
    const [subtotal, setSubtotal] = useState(0)

    const defaultCamping =
    {
        name: "camping",
        label: "Camping",
        value: 4,
        currency: "dolar",
        labelCurrency: "$"
    }

    useEffect(() => {
        if (props.controlCamping) {
            let cantidad = 0;
            let subtotal = 0;
            setCantidadTotalPasajeros(0);
            props.pasajeros.map((pax) => {
                cantidad += pax.cantidad;
            })
            subtotal += cantidad * defaultCamping.value * props.nochesCamping;
            setCantidadTotalPasajeros(cantidad);
            props.setSubtotal(prevState => prevState = subtotal);
        }
    }, [props.pasajeros, props.controlCamping])

    const componenteCamping = () => {
        if (props.controlCamping) {
            return (
                <Grid container spacing={4} key={"pax-camping"}>

                    <Box component={Grid} key="camping" item container spacing={1}>
                        <Grid item xs={12}>
                            <Box bgcolor={"#e3e3e3"}>
                                <Typography variant="subtitle1"
                                    component={"span"}><span>Camping. $4 por persona</span></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <TextField name="cantidad" label={"Cantidad"} required
                                value={cantidadTotalPasajeros} fullWidth type="number"
                                InputProps={{ inputProps: { min: 0, max: 200 } }}
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={5} md={3}>


                        </Grid>


                        <Grid item xs={3} md={6}>
                            <Typography variant={"body1"}> </Typography>
                        </Grid>


                        <Box component={Grid} item xs={2} md={2} textAlign={"right"}>
                            <Typography
                                variant={"caption"}>{"Subtotal"}</Typography>
                            <Typography variant={"body2"}>
                                {defaultCamping.labelCurrency}{props.subtotal//dia de cobro
                                }
                            </Typography>

                        </Box>
                    </Box>

                </Grid>
            )
        } else {
            return <></>
        }

    }
    return (
        <>
            {
                componenteCamping()
            }
        </>

    )
}

export default PasajerosCamping