import { generate } from "shortid";
import moment from "moment";

export const settings = {
    diasPrevios: 1,
    infoDeposito: "Cuenta en Colones\n" +
        "Banco Nacional de Costa Rica\n" +
        "Nombre del beneficiario: Fundación de Parques Nacionales\n" +
        "Cédula jurídica: 3-006-045912\n" +
        "Cuenta Banco Nacional: 100-01-080-006225-2 \n" +
        "Cuenta IBAN: CR94015108010010062250 \n\n" +
        "Cuenta en dólares\n" +
        "Cuenta Banco Nacional: 100-02-080-602240-1 \n" +
        "Cuenta Cliente: 15108010026022407 \n" +
        "Cuenta IBAN: CR04015108010026022407 \n" +
        "SWIFT: BNCRCRSJ\n "
};

export const capacidad = [
    { sector: "Horizontes", capacidad: 50 },
    { sector: "Marino", capacidad: 70 },
    { sector: "Santa Rosa", capacidad: 500 },
    { sector: "Pailas", capacidad: 500 },
    { sector: "Santa María", capacidad: 200 },
    { sector: "Naranjo", capacidad: 100 },
    { sector: "Murciélago", capacidad: 150 }
];
export const capacidadCamping = [
    { sector: "Naranjo", capacidad: 60 },
];

export const costosHorizontes = [
    {
        name: "adulto",
        label: "Adulto Nacional o Residente",
        value: 1000,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "menor",
        label: "Menor (6-12 años)",
        value: 500,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "exonerado",
        label: "Menores de 6 años, adultos mayores nacionales y personas con discapacidad",
        value: 0,
        currency: "colones",
        labelCurrency: "₡"
    },
    /* {
         name: "estudiante",
         label: "Estudiante",
         value: 1000,
         currency: "colones",
         labelCurrency: "₡"
     },*/

    {
        name: "adultoExtranjero",
        label: "Adulto extranjero",
        value: 10,
        currency: "dolar",
        labelCurrency: "$"
    },
    {
        name: "menorExtranjero",
        label: "Menor extranjero (2 - 12 años)",
        value: 5,
        currency: "dolar",
        labelCurrency: "$"
    }];


export const sectoresHorizontes = [
    { id: 0, label: "Arboretum", value: false },
    { id: 1, label: "Sendero El Guaracho", value: false },
    { id: 2, label: "Sendero Los Coyotes", value: false },
    { id: 3, label: "Sendero Bajo Sombra", value: false }
];

export const pasajerosDefualt = [{
    nombrePax: "",
    nacional: "",
    provincia: "",
    costo: 1000,
    tarifa: "adulto",
    moneda: "₡",
    id: generate()

}];

let minDate = moment().add(settings.diasPrevios, 'd');
minDate = moment(minDate).format('yyyy-MM-DD');



export const horasHorizontes = [
    { value: 8, label: "8:00 am" },
    { value: 9, label: "9:00 am" },
    { value: 10, label: "10:00 am" },
    { value: 11, label: "11:00 am" },
    { value: 12, label: "12:00 pm" },
    { value: 13, label: "1:00 pm" },
    { value: 14, label: "2:00 pm" },
    { value: 15, label: "3:00 pm" }
];

export const inputsHorizontes =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true
        },
        {
            name: 'nombre',
            label: "Nombre para la reservación",
            value: "",
            type: "text",
            required: true
        },
        {
            name: 'agencia',
            label: "Agencia de Turismo",
            value: "",
            type: "text",
            required: false
        },
        {
            name: 'deposito',
            label: "Número de depósito",
            value: "",
            type: "text",
            required: true
        }
    ];

export const detallesHorizontes =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true,
            position: "top"
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true,
            position: "top"
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true,
            position: "mid"
        },
        {
            name: 'nombre',
            label: "Nombre para la reservación",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'cedula',
            label: "cedula",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'deposito',
            label: "Número de depósito",
            value: "",
            type: "text",
            required: true,
            position: "bottom"
        }
    ];
export const defaultMetodoDePago = "Transferencia";
// Santa Rosa

export const horasSR = [
    { value: 8, label: "8:00 am" },
    { value: 9, label: "9:00 am" },
    { value: 10, label: "10:00 am" },
    { value: 11, label: "11:00 am" },
    { value: 12, label: "12:00 pm" },
    { value: 13, label: "1:00 pm" },
    { value: 14, label: "2:00 pm" },
    { value: 15, label: "2:30 pm" }
];

export const pasajerosSR = [{
    nombrePax: "",
    nacional: "",
    provincia: "",
    costo: 1100,
    tarifa: "adulto",
    moneda: "₡",
    id: generate()

}];


export const settingsSR = {
    diasPrevios: 0,
    infoDeposito: "Cuenta en Colones:\n" +
        "Banco Nacional de Costa Rica\n" +
        "Cuenta Banco Nacional: 00-01-000-041220-5 \n" +
        "Cuenta IBAN: CR15 0151 0001 0010 4122 05 \n" +
        "SINAC FONDO DE PARQUES NACIONALES  \n" +
        "Cédula Jurídica: 3-007-317912 \n\n" +
        "Cuenta en dólares \n" +
        "Cuenta Banco Nacional: 100-02-000-605009-0 \n" +
        "Cuenta IBAN: CR56 0151 0001 0026 0500 98 \n" +
        "SINAC FONDO DE PARQUES NACIONALES.\n" +
        "Cédula Jurídica: 3-007-317912 \n"
};


export const costosSantaRosa = [
    {
        name: "adulto",
        label: "Adulto Nacional o Residente",
        value: 1100,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "menor",
        label: "Menor (2-12 años)",
        value: 500,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "exonerado",
        label: "Menores de 2 años y adultos mayores",
        value: 0,
        currency: "colones",
        labelCurrency: "₡"
    },

    {
        name: "adultoExtranjero",
        label: "Adulto extranjero",
        value: 15,
        currency: "dolar",
        labelCurrency: "$"
    },
    {
        name: "menorExtranjero",
        label: "Menor extranjero (2 - 12 años)",
        value: 5,
        currency: "dolar",
        labelCurrency: "$"
    }];

minDate = moment()
minDate = moment(minDate).format('yyyy-MM-DD');
export const inputsSantaRosaLegacy =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true
        },
        {
            name: 'deposito',
            label: "Número de depósito",
            value: "Pago con tarjeta al ingresar al Parque",
            type: "text",
            required: true
        }
    ];



export const inputsSantaRosa =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true,
            position: "top"
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true,
            position: "top"
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true,
            position: "mid"
        },
        {
            name: 'nombre',
            label: "Nombre para la reservación",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'agencia',
            label: "Agencia de Turismo",
            value: "",
            type: "text",
            required: false,
            position: "mid"
        }
    ];

export const costosSREscuelas = [
    {
        name: "exnoeradoPorResolucion",
        label: "Personas exoneradas por resolución adjunta",
        value: 0,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "adulto",
        label: "Adulto Nacional o Residente",
        value: 1100,
        currency: "colones",
        labelCurrency: "₡"
    },

    {
        name: "estudiantes",
        label: "Estudiantes de Primaria y Secundaria",
        value: 500,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "menor",
        label: "Menor (2-12 años)",
        value: 500,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "exonerado",
        label: "Menores de 2 años y adultos mayores",
        value: 0,
        currency: "colones",
        labelCurrency: "₡"
    },

    {
        name: "adultoExtranjero",
        label: "Adulto extranjero",
        value: 15,
        currency: "dolar",
        labelCurrency: "$"
    },
    {
        name: "menorExtranjero",
        label: "Menor extranjero (2 - 12 años)",
        value: 5,
        currency: "dolar",
        labelCurrency: "$"
    }
];

export const inputsSREscuelas =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true,
            position: "top"
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true,
            position: "top"
        },
        {
            name: 'nombre',
            label: "Profesor Encargado",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true,
            position: "mid"
        },
        {
            name: 'telefono',
            label: "Teléfono",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'centroEducativo',
            label: "Centro Educativo",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'grado',
            label: "Grado de los estudiantes",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'charla',
            label: "Charla para estudiantes ($27)",
            value: "No",
            type: "charla",
            hora: "9:00 am",
            required: true,
            position: "mid",
            costo: 27,
            labelCurrency: "$"
        },
        {
            name: 'exoneracion',
            label: "Tiene resolución de exoneración",
            value: "No",
            type: "exoneracion",
            required: true,
            position: "mid",
            costo: 0,
        },
    ];

export const sectoresSantaRosa = [
    { id: 0, label: "Sitio Histórico Casona de Santa Rosa", value: false },
    { id: 1, label: "Sendero Universal Indio Desnudo", value: false },
    { id: 2, label: "Mirador Tierras Emergidas", value: false },

];


// Marino


export const inputsMarino =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true
        },
        {
            name: 'hora',
            label: "Hora",
            value: 6,
            type: "select",
            required: true
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true
        },
        {
            name: 'embarcacion',
            label: "Embarcación",
            value: "",
            type: "text",
            required: true
        },
        {
            name: 'matricula',
            label: "Matrícula",
            value: "",
            type: "text",
            required: true
        },
        {
            name: 'capitan',
            label: "Capitán",
            value: "",
            type: "text",
            required: true
        },
        { //6
            name: 'paisCapitan',
            label: "País",
            value: "",
            type: "text",
            required: true
        },
        {
            name: 'provinciaCapitan',
            label: "Provincia",
            value: "",
            type: "text",
            required: false
        },
        {
            name: 'marinero',
            label: "Marinero",
            value: "",
            type: "text",
            required: false
        },
        {
            name: 'paisMarinero',
            label: "País",
            value: "",
            type: "text",
            required: false
        },
        {
            name: 'provinciaMarinero',
            label: "Provincia",
            value: "",
            type: "text",
            required: false
        },
        {
            name: 'actividad',
            label: "Actividad a realizar",
            value: "Surf",
            type: "radio",
            required: true
        },
        { //12
            name: 'deposito',
            label: "Número de depósito",
            value: "",
            type: "text",
            required: true
        },
        { //13
            name: 'telefono',
            label: "Teléfono",
            value: "",
            type: "text",
            required: true
        }
    ];


export const sectoresMarino = [
    { id: 0, label: "Surf", value: "Surf", capacidad: 65 },
    { id: 1, label: "Buceo", value: "Buceo", capacidad: 40 },
    { id: 2, label: "Paseo recreativo (snorkel)", value: "Paseo recreativo", capacidad: 150 },
    { id: 3, label: "Sendero Mirador Isla San José", value: "Sendero Mirador Isla San José", capacidad: 0 },

];

export const horasMarino = [
    { value: 6, label: "6:00 am" },
    { value: 7, label: "7:00 am" },
    { value: 8, label: "8:00 am" },
    { value: 9, label: "9:00 am" },
    { value: 10, label: "10:00 am" },
    { value: 11, label: "11:00 am" },
    { value: 12, label: "12:00 pm" },
    { value: 13, label: "1:00 pm" },
    { value: 14, label: "2:00 pm" },

];

//Pailas


export const pasajerosPailas = [{
    cantidad: 1,
    nombrePax: "",
    nacional: "Costa Rica",
    provincia: "",
    costo: 1000,
    tarifa: "adulto",
    moneda: "₡",
    id: generate(),
    subtotal: 0
}];
export const cerrado = {
    "Santa Rosa": ['2022-07-01', '2022-07-02', '2022-07-03'],
    "Pailas": ['2022-07-01', '2022-07-02', '2022-07-03'],
    "Marino": ['2022-07-01', '2022-07-02', '2022-07-03'],
    "Horizontes": ['2022-07-01', '2022-07-02', '2022-07-03'],
    "Santa María": ['2022-07-01', '2022-07-02', '2022-07-03'],
    "Naranjo": ['2023-02-22', "2023-02-23", "2023-02-24", "2023-02-25", "2023-02-26", "2023-02-27"]
};

export const feriados = [
    '2022-12-27', '2023-01-03', '2023-04-11', '2023-07-24', '2023-08-14', '2024-07-25', '2024-08-15', '2022-04-12', '2022-07-03', '2022-07-04', '2022-07-05', '2022-07-11', '2022-07-12',
];


export const costosPailas = [
    {
        name: "adulto",
        label: "Adulto Nacional o Residente",
        value: 1000,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "menor",
        label: "Menor (2-12 años)",
        value: 500,
        currency: "colones",
        labelCurrency: "₡"
    },
    {
        name: "exonerado",
        label: "Menores de 2 años y adultos mayores",
        value: 0,
        currency: "colones",
        labelCurrency: "₡"
    },
    /* {
         name: "estudiante",
         label: "Estudiante",
         value: 500,
         currency: "colones",
         labelCurrency: "₡"
     },*/

    {
        name: "adultoExtranjero",
        label: "Adulto extranjero",
        value: 15,
        currency: "dolar",
        labelCurrency: "$"
    },
    {
        name: "menorExtranjero",
        label: "Menor extranjero (2 - 12 años)",
        value: 5,
        currency: "dolar",
        labelCurrency: "$"
    }];

minDate = moment();
minDate = moment(minDate).format('yyyy-MM-DD');

export const inputsPailas =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true,
            position: "top"
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true,
            position: "top"
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true,
            position: "mid"
        },
        {
            name: 'nombre',
            label: "Nombre para la reservación",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'agencia',
            label: "Agencia de Turismo",
            value: "",
            type: "text",
            required: false,
            position: "mid"
        },
        {
            name: 'deposito',
            label: "Número de depósito",
            value: "Pago con tarjeta al ingresar al Parque",
            type: "text",
            required: false,
            position: "bottom"
        }
    ];


export const sectoresPailas = [
    { id: 0, label: "Sitio Histórico Casona de Santa Rosa", value: false },
    { id: 1, label: "Sendero Universal Indio Desnudo", value: false },
    { id: 2, label: "Mirador Tierras Emergidas", value: false },

];

export const horasNaranjo = [
    { value: 8, label: "8:00 am" },
    { value: 9, label: "9:00 am" },
    { value: 10, label: "10:00 am" },
    { value: 11, label: "11:00 am" },
    { value: 12, label: "12:00 pm" },
    { value: 13, label: "1:00 pm" },
    { value: 14, label: "2:00 pm" }
];

export const inputsNaranjo =
    [

        {
            name: 'fecha',
            label: "Fecha",
            value: minDate,
            type: "date",
            required: true,
            position: "top"
        },
        {
            name: 'hora',
            label: "Hora",
            value: 8,
            type: "select",
            required: true,
            position: "top"
        },
        {
            name: 'email',
            label: "Email",
            value: "",
            type: "email",
            required: true,
            position: "mid"
        },
        {
            name: 'nombre',
            label: "Nombre para la reservación",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'placa',
            label: "Placa del vehículo",
            value: "",
            type: "text",
            required: true,
            position: "mid"
        },
        {
            name: 'agencia',
            label: "Agencia de Turismo",
            value: "",
            type: "text",
            required: false,
            position: "mid"
        }
    ];

export const campingNaranjo = {

    nochesCamping: 0,
    subTotalCamping: 0,
    actividades: [
        { id: 1, actividad: 'Por día', value: 'dia' },
        { id: 2, actividad: 'Acampar', value: 'acampar' },


    ]
}