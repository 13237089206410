import React, {useContext} from "react";
import {AuthContext} from "../context/auth";
import {Link} from 'gatsby'
import Alert from '@material-ui/lab/Alert';

const LoggedIn = ({children}) => {
    const {user, setUser} = useContext(AuthContext);
    
    if (user) {
        return (
            <div className="loggedIn">
                {children}
            </div>
        )
    }
    return (
        <>
            <Alert severity="error"> Para acceder a esta función del sistema necesita autentificarse. <Link
                to={"/app/login"}>Acceder al Sistema</Link>
            </Alert>
        </>
    )
}

export default LoggedIn
