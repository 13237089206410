import React, {useEffect, useState} from "react";
import axios from "axios";
import {capacidad} from "./defaults";
import {Paper, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    codigoRes :{
        padding:"0.5rem",
        backgroundColor:"rgb(211,47,47)",
        color:"white",
        display:"flex"
        
    },
}));

const Disponibilidad = (props) => {
   const classes = useStyles();
    const getCapacidadMaxima = () => {
        return capacidad.filter((sector) => {
            if  (sector.sector === props.sector)
                return sector.capacidad
        })
    }
    const [capacidadMaxima, setcapacidadMaxima] = useState(getCapacidadMaxima);
    const [paxReservados, setPaxReservados] = useState(0);
    const [disponiblesL, setDisponiblesL] = useState(0)
    
    async function paxConReserva (){
        let paxCount = 0;
        await  axios.get("https://reservaciones.acguanacaste.ac.cr/getDisponibilidad.php", {
            params: {
                fecha: props.fecha,
                sector:encodeURI(props.sector),
                disponibilidad: 1
            }
        })
            .then((response) => {
                paxCount = response.data.pax;
                setPaxReservados(paxCount)
                const newValue = capacidadMaxima[0].capacidad - paxCount - props.paxCount;
                setDisponiblesL(newValue);
                props.onChange(newValue);
                
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    
    useEffect( () => {
        paxConReserva()
    },[props.fecha,props.sector,props.paxCount,props.cuposDisponibles])
    
    useEffect( () => {
       paxConReserva()
    }, [])
    
    
    return (
        
        <Paper className={classes.codigoRes} >
            <Typography variant={"body2"}>Cupos disponibles:</Typography>
            <Typography variant="h5">&nbsp;{(props.cuposDisponibles >= 0)?props.cuposDisponibles:0}</Typography>
        </Paper>
    )
}

export default Disponibilidad