import React, {useState, useEffect} from "react";
import {
    Grid,
} from "@material-ui/core";
import {useQueryParam, Reservacion} from "use-query-params";
import axios from "axios"
import Alert from '@material-ui/lab/Alert';

import BuscarReservacion from "./buscarReservacion";
import Marino from "./marino";
import Formulario from "./formulario";
import FormularioPrueba from "./naranjo/formularioPrueba";
import {
    campingNaranjo,
    cerrado, costosHorizontes,
    costosPailas,
    costosSantaRosa, detallesHorizontes,
    horasHorizontes, horasNaranjo,
    horasSR, inputsNaranjo,
    inputsPailas,
    inputsSantaRosa
} from "./defaults";

const Edit = () => {
    return (
        <>
            <EditReservacion />
        </>
    )

}


const EditReservacion = () => {
    let [reservacion] = useQueryParam("reservacion", Reservacion)
    const [data, setData] = useState([])
    const [error, setError]= useState("")


    useEffect(() => {
        async function getReservation() {
            setError("")
            axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php?action=oneReservation&reservacion=' + reservacion)
                .then(function (response) {
                    const info = response.data
                    if (typeof info.error != "undefined"){
                        setError("No se ha podido encontrar una reservación con el número: "+reservacion)
                    }else{
                        setData(info);
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                        setData([])
                    } else {
                        setError("Hubo un error al conseguir la información de la reserva. " + error)
                        setData([])
                    }
                })
        }
        if (typeof reservacion !== 'undefined')
            getReservation()
    }, [reservacion]);



    if (typeof reservacion === 'undefined'){
        return (
            <BuscarReservacion />
        )
    }
    if (error !== ""){
        return (
            <>
                <Alert severity="error">{error}</Alert>
                <Grid container>
                    <Grid item md={12}>
                        <>
                            <BuscarReservacion />
                        </>
                    </Grid>
                </Grid>
            </>
        )
    }
    const fechasCerrado = cerrado[data.sector];

    switch (data.sector){
        case "Marino":{
            return (<Marino data={data} />)
        }
        case "Pailas":{
            return (<Formulario
                sector = {data.sector}
                horas = {horasSR}
                inputFields =  {inputsPailas}
                costos = {costosPailas}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[1]}
                cerrado = {fechasCerrado}
                tipo={data.tipo}
                iva={1}
                data = {data}
                
        />)
        }
        case "Santa María":{
            return (<Formulario
                sector = {data.sector}
                horas = {horasSR}
                inputFields =  {inputsPailas}
                costos = {costosPailas}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[1,2,3]}
                cerrado = {fechasCerrado}
                tipo={data.tipo}
                iva={1}
                data = {data}
            />)
        }
        case "Santa Rosa":{
            return (<Formulario
                sector = {data.sector}
                horas = {horasSR}
                inputFields =  {inputsSantaRosa}
                costos = {costosSantaRosa}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[2]}
                cerrado = {fechasCerrado}
                tipo={data.tipo}
                iva={1}
                data = {data}
            />)
        }
        // case "Naranjo":{
        //     return (<FormularioNaranjo
        //         sector = {data.sector}
        //         horas = {horasNaranjo}
        //         inputFields =  {inputsNaranjo}
        //         costos = {costosSantaRosa}
        //         pago = "Pago con tarjeta al ingresar al Parque"
        //         diasPevios = {0}
        //         diaCerrado = {[2]}
        //         cerrado = {fechasCerrado}
        //         tipo={data.tipo}
        //         iva={1}
        //         data = {data}
        //         campingNaranjo = {campingNaranjo}
        //     />)
        // }
        case "Naranjo":{
            return (<FormularioPrueba
                sector = {data.sector}
                horas = {horasNaranjo}
                inputFields =  {inputsNaranjo}
                costos = {costosSantaRosa}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[2]}
                cerrado = {fechasCerrado}
                tipo={data.tipo}
                iva={1}
                data = {data}
                campingNaranjo = {campingNaranjo}
            />)
        }
        case "Horizontes":{
            return (<Formulario
                sector = {data.sector}
                horas = {horasHorizontes}
                inputFields =  {detallesHorizontes}
                costos = {costosHorizontes}
                pago = "Transferencia"
                diasPevios = {1}
                diaCerrado = {[-1]}
                cerrado = {fechasCerrado}
                tipo={data.tipo}
                iva={0}
                data = {data}
            />)
        }
        default:
            return (<>Obteniendo los datos de su reservación</>)
    }


}
export default Edit;
