import React, {useEffect, useState} from "react";
import {
    Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField,
    FormControl, Select, MenuItem, InputLabel, makeStyles, Typography, Button
} from "@material-ui/core";
import {Link} from "gatsby";
import axios from "axios"
import {observer} from 'mobx-react-lite'



import LoggedIn from "./loggedIn";
import {useFilterStore} from "../context/filter";
import {autorun} from "mobx";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import  {getToken} from "../services/auth";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const Report = observer( () =>{
    return (
        <>
            <LoggedIn>
                <ReporteReservaciones />
            </LoggedIn>
            
        </>
        
    )
})
export default Report

const ReporteReservaciones = observer( () => {
    const [data, setData] = useState([])
    const filter = useFilterStore()
    const [error, setError] = useState(null)
    
    const classes = useStyles();
    const tk = getToken();
    async function getReservation() {
        await axios.get(process.env.GATSBY_BACKEND_SERVER+'endpoint.php', {
            params: {
                action: "reporteRes",
                sector: encodeURI(filter.sector),
                desde: filter.desde,
                hasta:  filter.hasta
            }
        })
            .then(function (response) {
                const info = response.data
                setData(info)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                    setData([])
                } else {
                    setError("Hubo un error al conseguir la información de la reserva. " + error)
                    setData([])
                }
            })
    }
    
    useEffect(() => {
        getReservation()
        
    }, []);
    
    useEffect( () => autorun( () => {
        getReservation()
    }), [filter.desde, filter.hasta, filter.sector]);

    const handleDownload = () => {

    }
    
    return (
        <>
            <Typography variant={"h6"} component={"h1"}>
                Reporte de reservaciones Sector {filter.sector}: {filter.displayDesde} - {filter.displayHasta}
            </Typography>
            <Grid container>
                {(error==null)?null:(
                    <Grid item container xs={12}>
                        <Grid item container alignItems="center" style={{backgroundColor: "rgb(253, 236, 234)", padding: "1rem"}}>
                            <Grid item xs={1}>
                                <ReportProblemIcon style={{color: "#ad1d1d"}}/>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant={"body2"} style={{color: "#ad1d1d"}} >
                                    {error}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={4} md={3}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="desde"
                            label="Desde"
                            type="date"
                            format="dd/MM/yyyy"
                            fullWidth
                            required
                            defaultValue={filter.desde}
                            inputProps={{}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                const fecha = e.target.value;
                                filter.setDesde(fecha)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} md={3}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="hasta"
                            label="Hasta"
                            type="date"
                            format="dd/MM/yyyy"
                            fullWidth
                            required
                            defaultValue={filter.hasta}
                            inputProps={{}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                const fecha = e.target.value;
                                filter.setHasta(fecha)
                            }}
                        />
                    </FormControl>
                </Grid>
                
                <Grid item xs={3}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                        <Select
                            labelId="sector-label"
                            id="sector"
                            value={filter.sector}
                            onChange={function (event){
                                const sector = event.target.value;
                                filter.setSector(sector)
                            }}
                        >
                            <MenuItem value={"Todos"}>Todos</MenuItem>
                            <MenuItem value={"Horizontes"}>Horizontes</MenuItem>
                            <MenuItem value={"Marino"}>Marino</MenuItem>
                            <MenuItem value={"Santa Rosa"}>Santa Rosa</MenuItem>
                            <MenuItem value={"Pailas"}>Pailas</MenuItem>
                            <MenuItem value={"Santa María"}>Santa María</MenuItem>
                            <MenuItem value={"Naranjo"}>Naranjo</MenuItem>
                            <MenuItem value={"Murciélago"}>Murciélago</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl}>
                        <Button onClick={handleDownload}>Bajar</Button>
                        <InputLabel id="demo-simple-select-label">
                            <a href={"/endpoint.php?action=reporteRes&sector="+filter.sector+"&desde="+filter.desde+"&hasta="+filter.hasta+"&format=csv&tk="+tk}>
                                Bajar Reporte</a>
                        </InputLabel>
                    
                    </FormControl>
                </Grid>
            </Grid>
            <TablaReservaciones data={data} />
           
        </>
    )
})


function TablaReservaciones (props) {
    return (
        <>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Reservación</TableCell>
                        <TableCell>Sector</TableCell>
                        <TableCell>No. Depósito</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>País</TableCell>
                        <TableCell>Provincia</TableCell>
                        <TableCell>Tarifa</TableCell>
                        <TableCell>Costo</TableCell>
                        <TableCell>IVA</TableCell>
                        <TableCell>Actividad</TableCell>
            
                    </TableRow>
                </TableHead>
        
                <TableBody>
                    {props.data.map((reservacion, index) => {
                        console.log("e", reservacion)
                        return (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    <Link
                                        to={"/app/reservacion?reservacion=" + reservacion.reservacion}>{reservacion.reservacion}</Link>
                                </TableCell>
                                <TableCell>{reservacion.sector} </TableCell>
                                <TableCell>{reservacion.deposito} </TableCell>
                                <TableCell>{reservacion.fechaS}</TableCell>
                                <TableCell>{reservacion.cantidad} </TableCell>
                                <TableCell>{reservacion.nombrePax} </TableCell>
                                <TableCell>{reservacion.nacional}</TableCell>
                                <TableCell>{reservacion.provincia}</TableCell>
                                <TableCell>{reservacion.tarifa}</TableCell>
                                <TableCell>{reservacion.moneda}{reservacion.costo}</TableCell>
                                <TableCell>{reservacion.iva}</TableCell>
                                <TableCell>{reservacion.actividad}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>
    )
}
