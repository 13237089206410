import { navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { generate } from "shortid";
import { feriados } from "../defaults"
import axios from "axios";
import { produce } from "immer";
import {
  Button,
  FormControl, FormControlLabel,
  FormLabel,
  Grid,
  Hidden, IconButton,
  InputLabel,
  makeStyles, Radio, RadioGroup,
  Select,
  TextField
} from "@material-ui/core";
import DatePicker from "react-date-picker";
import Disponibilidad from "../disponibilidad";
import DisponibilidadCamping from "./disponibilidadCamping";
import Typography from "@material-ui/core/Typography";
import Pasajeros from "../pasajeros";
import PasajerosCamping from "./pasajerosCamping";
import SendIcon from "@material-ui/icons/Send";
import CircularProgress from "@material-ui/core/CircularProgress";
//import ReCAPTCHA from "react-google-recaptcha";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { formatDate } from "../dateFormat";
import useTipoDeCambio from "../tdc";
import moment from "moment";
import { Label } from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    display: "flex"
  },
  displayLinebreak: {
    whiteSpace: "pre-line"
  },
  section: {
    marginTop: "1rem"
  },
  calendar: {
    border: "none"
  }
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
//const recaptchaRef = React.createRef();

/*
Props:
 sector ={sectorACG} : Sector de ACG "Santa Rosa"
 horas = {horasSR} : array con horas de reservación
 inputFields =  {inputsPailas} : array de los fields
 costos = {costosPailas} : array con tarifas de cobro
 pago = "Pago con tarjeta al ingresar al Parque" | "Trasnferencia"
 diasPevios = {0} : Días de antelación necesarios para una reservación
 diaCerrado = {[1]} : Días de la semana que está cerrado 0 Domingo - 6 Sábado | -1 ninguno
 cerrado = ['2022-07-01']: Array con fechas en las que un sector puede estar cerrado
 */

const FormularioPrueba = (props) => {

  FormularioPrueba.defaultProps = {
    tipo: "turistas",
    cerrado: [],
    iva: 1
  }
  const classes = useStyles();
  const [reservationId, setReservationId] = useState();
  const estado = props.tipo === "escuela" ? "pendiente" : "activa"
  const [charla, setCharla] = useState("No")
  const [currentPax, setCurrentPax] = useState(0);
  const tipoDeCambio = useTipoDeCambio()



  //Parámetros del sector
  const sectorACG = props.sector;
  const [inputFields, setInputFields] = useState(props.inputFields);

  const newPassenger = props.costos.map((unTipo) => {
    const nacional = (unTipo.currency === "colones") ? "Costa Rica" : "";
    const provincia = (unTipo.currency === "colones") ? "" : "Extranjero";
    return {
      cantidad: 0,
      nombrePax: unTipo.label,
      nacional,
      provincia,
      costo: unTipo.value,
      tarifa: unTipo.name,
      moneda: unTipo.labelCurrency,
      id: unTipo.name,
      subtotal: 0
    }
  })

  let [errorMsg, setErrorMsg] = useState(null)
  let [data, setData] = useState(null);
  const [desglosePagos, setDesglosePagos] = useState([]);
  const [cuposDisponibles, setCuposDisponibles] = useState(0);
  const [pasajeros, setPasajeros] = useState(newPassenger);
  const [paxCount, setPaxCount] = useState(0);
  const [disabledSubmit, setDisableSumbit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comprobante, setComprobante] = useState([]);
  const [diaDeCobro, setDiaDeCobro] = useState(1) // para manejar dias gratis
  const [comprobantesActuales, setComprobantesActuales] = useState([]);

  let minDateDate = new Date();
  let minCampingDateDate = new Date();
  minDateDate.setDate(minDateDate.getDate() + props.diasPevios); //todo si se neceistan los feriados por props como en el fe?
  while ((props.diaCerrado.includes(minDateDate.getDay()) && !feriados.includes(formatDate(minDateDate))) || props.cerrado.includes(formatDate(minDateDate))) {
    minDateDate.setDate(minDateDate.getDate() + 1);
    minCampingDateDate.setDate(minCampingDateDate.getDate() + 2);
  }


  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(minDateDate);
  const fechaFormated = (`${year}-${month}-${day}`)

  const [fechaR, setFechaR] = useState(fechaFormated);
  const [fecha, setFecha] = useState(minDateDate);
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  const [currentDate, setCurrentDate] = useState(fechaFormated)


  //VARIABLES NUEVAS DEL CAMPING
  const [controlCamping, setControlCamping] = useState(false);

  const [fechaSalida, setFechaSalida] = useState();
  const [fechaSalidaR, setFechaSalidaR] = useState(fechaFormated);

  const [maxDateCamping, setMaxDateCamping] = useState(new Date());
  const [minDateCamping, setMinDateCamping] = useState(new Date());
  const [nochesCamping, setNochesCamping] = useState(0);
  const [cantidadTotal, setCantidadTotal] = useState(0);

  const [subtotalCamping, setSubTotalCamping] = useState(0);

  const [cuposDisponiblesCamping, setCuposDisponiblesCamping] = useState(0);


  //Post del formulario al servidor
  useEffect(() => {
    async function sendReservation() {

      if (data !== null) {
        setLoading(true)
        const allData = new FormData();
        if (comprobante !== null) {
          comprobante.forEach(file => {
            allData.append(generate(), file);
          });
        }
        allData.append('data', JSON.stringify(data))
        axios.post(process.env.SERVER_BACKEND_RESERVACIONES + "doReserve.php", allData)
          // axios.post("http://localhost:9000/doReserve.php", data)
          .then((response) => {
            // console.log(response);
            if (response.data === 'Success') {
              navigate("/app/reservacion?reservacion=" + reservationId, {
                state: {
                  data
                }
              })
              setLoading(false)
            } else {
              setErrorMsg(null);
              let error = []
              error.push("Ha habido un error haciendo su reservación . " + response.data);
              setLoading(false);
              setErrorMsg(error);
              executeScroll()
            }
          })
      }
    }
    sendReservation();
  }, [data]);

  //Calculo de costos
  useEffect(() => {
    let isSubscribed = true;

    async function recalcularCosto() {
      if (isSubscribed) {
        let subTDolares = pasajeros.reduce(function calcSTD(accumulator, pax) {
          return (pax.moneda === '$') ? accumulator +
            // (pax.costo * pax.cantidad * diaDeCobro) 
            pax.subtotal
            : accumulator;
        }, 0)
        const subTNacionalesLocal = pasajeros.reduce(function calcSTN(accumulator, pax) {
          return (pax.moneda === "₡") ? accumulator +
            // (pax.costo * pax.cantidad * diaDeCobro) 
            pax.subtotal
            : accumulator;
        }, 0)
        if (props.tipo === "escuela") {
          if (charla !== "No") {
            subTDolares += 27;
          }
        }
        const countPasajeros = pasajeros.reduce(function calcSTN(accumulator, pax) {
          if (isNaN(pax.cantidad)) {
            return accumulator
          }
          return accumulator + parseInt(pax.cantidad);
        }, 0)
        setPaxCount(countPasajeros)

        if (controlCamping) {
          subTDolares = subTDolares + subtotalCamping;
        }
        const ivaDolares = subTDolares * 0.13 * props.iva;
        const totalDolares = subTDolares + ivaDolares;
        const totalDolaresColonesLocal = totalDolares > 0 ? (totalDolares * tipoDeCambio) : 0;
        const iva = subTNacionalesLocal * 0.13 * props.iva;
        const SubtotalNacionalDisplay = subTNacionalesLocal;
        const totalNacionales = subTNacionalesLocal + iva;
        const totalColones = totalNacionales + totalDolaresColonesLocal
        const tdc = (typeof tipoDeCambio === 'number') ? "(TC:" + tipoDeCambio.toString() + ")" : "";
        const formatColones = { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'CRC' };
        const formatDolares = { maximumFractionDigits: 2, minimumFractionDigits: 2, };
        const desglosePagos = [
          { label: "Subtotal dólares", value: "$" + subTDolares.toLocaleString('es-CR', formatDolares) },
          { label: "I.V.A. ", value: "$" + ivaDolares.toLocaleString('es-CR', formatDolares) },
          { label: "Total dólares", value: "$" + totalDolares.toLocaleString('es-CR', formatDolares) },
          { label: "Subtotal dólares en colones " + tdc, value: totalDolaresColonesLocal.toLocaleString('es-CR', formatColones) },
          { label: "", value: "____________" },
          { label: "Subtotal colones", value: SubtotalNacionalDisplay.toLocaleString('es-CR', formatColones) },
          { label: "I.V.A. ", value: iva.toLocaleString('es-CR', formatColones) },
          { label: "Total colones", value: totalNacionales.toLocaleString('es-CR', formatColones) },
          { label: "", value: "____________" },
          { label: "Total ", value: totalColones.toLocaleString('es-CR', formatColones) },
        ];
        await setDesglosePagos(desglosePagos);
      }
    }

    recalcularCosto()
    return () => (isSubscribed = false);
  }, [pasajeros, charla, diaDeCobro, tipoDeCambio, controlCamping, subtotalCamping])

  //Cargar datos para editar
  useEffect(() => {
    if (props.data.reservacion) {
      setReservationId(props.data.reservacion)
      setInputFields(props.data.detalles)
      setPasajeros(props.data.pasajeros)
      setCurrentPax(props.data.pasajeros.length)
      const timestamp = Date.parse(props.data.fechaS + "T00:00:00-0600")
      setTheDate(new Date(timestamp))
      setCurrentDate(props.data.fechaS)
      setDesglosePagos(props.data.desglosePagos)
      if (props.data.camping !== 'undefined') {
        setControlCamping(props.data.camping)
        setSubTotalCamping(props.data.paxCamping)
        const fechaSalida = Date.parse(props.data.fechaSalida + "T00:00:00-0600")
        setFechaSalida(new Date(fechaSalida));
        calcularMinDateMaxDateCamping();
      } else {
        setControlCamping(false);
      }
      if (props.tipo === "escuela") {
        const charlaValue = props.data.detalles.filter(x => x.name === "charla")[0].value
        setCharla(charlaValue)
      }
      if (typeof props.data.comprobantes !== "undefined")
        setComprobantesActuales(props.data.comprobantes)
      else
        setComprobantesActuales([])
    }
  }, [])



  useEffect(() => {
    calcularNochesCamping()
    setSubTotalCamping(props.data.paxCamping)
  }, [nochesCamping, fechaSalida])


  useEffect(() => {
    calcularMinDateMaxDateCamping()
  }, [nochesCamping])




  /*
  Desactiva los botones de agregar pasajero y enviar en caso de no haber cupos.
   */
  const handleCuposChanges = (cupo) => {
    setCuposDisponibles(cupo)
    if (cupo === 0) {
      setErrorMsg(null)
    } else if (cupo < 0) {
      // setDisableSumbit(true)
      // setErrorMsg(["está reservando para más personas que los cupos disponibles"])
    }
    else {
      setDisableSumbit(false)
      setErrorMsg(null)
    }
  }



  //Validación
  const validateForm = () => {
    let valid = true;
    let errores = [];
    inputFields.forEach(function (field, index) {
      if (field.required && (typeof field.value == "undefined" || field.value.length < 1)) {
        errores.push(field.label);
        valid = false
      }
    })

    let nan, procedencia, hayExonerados = false;
    pasajeros.forEach(function (pax, index) {
      if (isNaN(pax.cantidad) && !nan) {
        errores.push("verifique que todas las cantidades tengan 0 o un número de personas a reservar");
        valid = false;
        nan = true;
      }
      if (pax.cantidad > 0 && ((pax.nacional === "Costa Rica" && pax.provincia === "") || pax.nacional === "") && !procedencia) {
        errores.push("indicar procedencia de los pasajeros")
        valid = false;
        procedencia = true;
      }

      if (pax.id === "exnoeradoPorResolucion" && pax.cantidad > 0) {
        hayExonerados = true;
      }
    })
    if (paxCount < 1) {
      valid = false;
      errores.push("seleccione la cantidad de personas que desea reservar");
    }
    if (desglosePagos === null) {
      valid = false;
      errores.push("categoría de pago");
    }
    if (inputFields[0].value < new Date()) {
      valid = false;
      errores.push("fecha de reservación mayor a hoy")
    }
    if (props.pago === "Transferencia") {
      if (comprobante.length + comprobantesActuales.length < 1) {
        valid = false;
        errores.push("debe de adjuntar el comprobante de depósito")
      }
    }
    if (props.tipo === "escuela") {
      const exonerado = inputFields.filter(field => field.type === "exoneracion")[0]
      if ((exonerado.value === "Si" || hayExonerados) && comprobante.length + comprobantesActuales.length < 1) {
        valid = false;
        errores.push("adjuntar la resolución de exoneración")
      }
    }
    if (errores.length > 0) {
      errores.unshift("Los siguientes campos son requeridos: ")
      executeScroll()
    }

    setErrorMsg(errores)
    return valid;
  };

  const handleSubmit = () => {
    setErrorMsg(null);
    setData(null);
    try {
      const validForm = validateForm();
      const x = comprobantesActuales.map((value) => {
        return value.file;
      })
      if (validForm) {
        if (controlCamping) {
          setData([{
            sector: encodeURI(sectorACG),
            reservacion: reservationId,
            detalles: inputFields,
            pasajeros: pasajeros,
            fecha: fechaR,
            status: estado,
            pago: props.pago,
            desglosePagos,
            tipo: props.tipo,
            comprobantesActuales: x,
            action: "edit",
            camping: controlCamping,
            fechaSalida: fechaSalidaR,
            nochesCamping: nochesCamping,
            paxCamping: subtotalCamping

          }]);
        } else {
          setData([{
            sector: encodeURI(sectorACG),
            reservacion: reservationId,
            detalles: inputFields,
            pasajeros: pasajeros,
            fecha: fechaR,
            status: estado,
            pago: props.pago,
            desglosePagos,
            tipo: props.tipo,
            comprobantesActuales: x,
            action: "edit",

          }]);
        }


      }
    }
    catch (e) {
      setErrorMsg(["Error al procesar su solicitud: " + e.error])
      executeScroll()
    }
  }


  const setTheDate = (theDate) => {
    setFecha(theDate)
    const fechaFormated = formatDate(theDate);
    setFechaR(fechaFormated)
    setInputFields(currentInputFields =>
      produce(currentInputFields, v => {
        v[0].value = fechaFormated;
      }))
    const [{ value: month }, , { value: day }] = dateTimeFormat.formatToParts(theDate);
    const diaGratis = (month === 8 && day === 24) ? 0 : 1; //24 de agosto día de parques es gratis la entrada
    setDiaDeCobro(diaGratis)
  }

  const setTheDateCamping = (theDate) => {
    setFechaSalida(theDate)
    const fechaFormated = formatDate(theDate);
    setFechaSalidaR(fechaFormated)
    /*setInputFields(currentInputFields =>
        produce(currentInputFields, v => {
            v[0].value = fechaFormated;
        }))*/
    //@todo: Va a tener que considerar en el cálculo del camping esto.
    const [{ value: month }, , { value: day }] = dateTimeFormat.formatToParts(theDate);
    const diaGratis = (month == 8 && day == 24) ? 0 : 1; //24 de agosto día de parques es gratis la entrada
    setDiaDeCobro(diaGratis)

  }

  // useEffect(() => {
  //   modificarFechaEntradaParaCamping()
  // }, [controlCamping])

  const modificarFechaEntradaParaCamping = () => {
    if (fecha.getDay() === 1 && controlCamping) {
      const proximoMiercoles = new Date(fecha);
      proximoMiercoles.setDate(fecha.getDate() + 2);

      setFecha(proximoMiercoles);

    }
    else if (fecha.getDay() === 2 && controlCamping) {
      const proximoMiercoles = new Date(fecha);
      proximoMiercoles.setDate(fecha.getDate() + 1);

      //setFecha(proximoMiercoles);
    }
    else {
      const proximoMiercoles = new Date();
      setFecha(proximoMiercoles);
      //console.log(proximoMiercoles)
    }
  }
  const calcularNochesCamping = () => {


    let fecha1 = moment(fecha).startOf('day');

    let fecha2 = moment(fechaSalida).startOf('day');

    let noches = 0;

    noches = fecha2.diff(fecha1, 'days')
    setNochesCamping(noches);

    let cantidad = (props.data.paxCamping / noches) / 4;
    setCantidadTotal(cantidad);

    pasajeros.map((field, index) => {
      setPasajeros(currentPasajeros =>
        produce(currentPasajeros, v => {
          if (controlCamping) {
            v[index].subtotal = v[index].cantidad * v[index].costo * diaDeCobro * noches;
          } else {
            v[index].subtotal = cantidad * v[index].costo * diaDeCobro;
          }

        }))
    })

  }

  const calcularMinDateMaxDateCamping = async () => {
    if (fecha !== null) {
      if (controlCamping && fecha.getDay() !== 1 && fecha.getDay() !== 2) {
        const diaSemana = fecha.getDay();

        // Calcular cuántos días faltan hasta el próximo lunes
        let diasHastaLunes;
        if (diaSemana === 1) {
          // Si hoy es lunes, el próximo lunes es una semana después
          diasHastaLunes = 7;
        } else if (diaSemana === 0) {
          diasHastaLunes = 1;
        }
        else {
          // De lo contrario, calcula los días restantes hasta el próximo lunes
          diasHastaLunes = 1 + (7 - diaSemana);
        }

        // Calcular la fecha del próximo lunes
        const proximoLunes = new Date(fecha);
        proximoLunes.setDate(fecha.getDate() + diasHastaLunes);

        const minDateCamp = new Date(fecha)
        minDateCamp.setDate(fecha.getDate() + 1)

        // Establecer el maxDate como el próximo lunes
        setMaxDateCamping(proximoLunes)

        setMinDateCamping(minDateCamp)

        //setFechaSalida(proximoLunes)

        let [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(proximoLunes);
        let fechaFormated = (`${year}-${month}-${day}`)
        //setFechaSalidaR(fechaFormated);
      }
      // else if (fecha.getDay() === 1 && controlCamping) {
      //   modificarFechaEntradaParaCamping();
      // }
      // else if (fecha.getDay() === 2 && controlCamping) {
      //   modificarFechaEntradaParaCamping();
      // }
      // else {
      //   setFechaSalida(null);
      // }
    } else {
      setFechaSalida(null);
    }
  }



  const ComponenteFechaSalida = () => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="fechaSalida" className={" MuiInputLabel-shrink MuiFormLabel-filled"}>Fecha de salida</InputLabel>
        <DatePicker
          id={"fechaSalida"}
          value={fechaSalida}
          locale={"es-CR"}
          readOnly
          //tileDisabled={({date}) => date.getDay() === 1}
          // tileDisabled={({ date }) => {

          //     const dateFormated = formatDate(date)
          //     //const setiembre  = new Date("09/26/2023").getTime()
          //     //const naranjoSet =  (sectorACG == "Naranjo") && date > setiembre

          //     return (props.diaCerrado.includes(date.getDay()) && !props.feriados.includes(dateFormated)) || props.cerrado.includes(dateFormated) ///|| naranjoSet
          // }}
          className={classes.calendar + " MuiInputBase-root MuiInput-root MuiInput-underline  MuiInputBase-formControl MuiInput-formControl"}
          onChange={(value) => {
            setTheDateCamping(value)
            // calcularNochesCamping(value)
          }}
          minDate={minDateCamping}
          maxDate={maxDateCamping}
        />
      </FormControl>
    )
  }

  const handleCuposCampingChanges = (cupo) => {
    setCuposDisponiblesCamping(cupo)
    if (cupo === 0) {
      setErrorMsg(null)
    } else if (cupo < 0) {
      setDisableSumbit(true)
      setErrorMsg(["Capacidad máxima alcanzada"])
    }
    else {
      setDisableSumbit(false)
      setErrorMsg(null)
    }
  }

  return (

    <>

      <Grid item xs={12} md={8}>
        <RadioGroup row aria-label="actividad" name="sectoresVisita" defaultValue="Surf" onChange={(e) => {
          const actividad = e.target.value;
          if (actividad === 'acampar') {
            setControlCamping(true);
            //modificarFechaEntradaParaCamping()
          }
          else {
            setControlCamping(false);
          }
        }}>
          {
            props.campingNaranjo.actividades.map((value, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={value.value}
                  control={<Radio />}
                  label={value.actividad} />
              )
            })
          }
        </RadioGroup>
      </Grid>


      <Grid container spacing={3} ref={myRef}>
        <Grid item xs={12}>
          <FormErrors errors={errorMsg} />
        </Grid>
        {(diaDeCobro === 1) ? null : (
          <Grid item container alignItems="center"
            style={{ backgroundColor: "rgb(235,253,234)", padding: "1rem" }} xs={12}>
            <Grid item xs={1}>
              <ReportProblemIcon style={{ color: "#356732" }} />
            </Grid>
            <Grid item xs={11} md={11}>
              <Typography variant={"body2"} style={{ color: "#356732" }}>
                El 24 de Agosto se celebra el Día de los Parques Nacionales y la entrada es gratuita
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4} md={4}>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="fecha" className={" MuiInputLabel-shrink MuiFormLabel-filled"}>Fecha del Viaje</InputLabel>
            <DatePicker
              id={"fecha"}
              value={fecha}
              locale={"es-CR"}
              //tileDisabled={({date}) => date.getDay() === 1}
              tileDisabled={({ date }) => {
                const dateFormated = formatDate(date)

                return (props.diaCerrado.includes(date.getDay()) && !feriados.includes(dateFormated)) || props.cerrado.includes(dateFormated)
              }}
              className={classes.calendar + " MuiInputBase-root MuiInput-root MuiInput-underline  MuiInputBase-formControl MuiInput-formControl"}
              onChange={(value) => {
                setTheDate(value)
                calcularMinDateMaxDateCamping()
              }}
              minDate={minDateDate}
            />
          </FormControl><br></br>

          {controlCamping && <ComponenteFechaSalida />}

          {controlCamping && <p>Las noches de camping {nochesCamping}</p>}
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="hora">Hora de llegada</InputLabel>
            <Select
              label={"Hora de llegada"}
              native
              className={classes.textField}
              value={inputFields[1].value}
              onChange={(e) => {
                const hora = e.target.value;
                setInputFields(currentInputFields =>
                  produce(currentInputFields, v => {
                    v[1].value = hora;
                  }))
              }}
              inputProps={{
                name: 'hora',
                id: 'hora',

              }}
            >
              {props.horas.map((unaHora, index) => {
                return (<option key={index} value={unaHora.value}>{unaHora.label}</option>)
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={3} md={2}>
          {(controlCamping) ?
            <DisponibilidadCamping sector={sectorACG}
              fecha={fechaR}
              fechaSalida={fechaSalidaR}
              paxCount={paxCount}
              cuposDisponibles={cuposDisponiblesCamping}
              onChange={handleCuposCampingChanges}
              controlCamping={controlCamping}
            /> :
            <Disponibilidad sector={sectorACG}
              fecha={fechaR}
              paxCount={paxCount}
              cuposDisponibles={cuposDisponibles}
              onChange={handleCuposChanges}
              editDate={currentDate}
            />
          }




        </Grid>

      </Grid>
      <Detalles
        inputFields={inputFields}
        setInputFields={setInputFields}
        position={"mid"}
        charla={charla}
        setCharla={setCharla}
        setComprobante={setComprobante}
        width={4}

      />

      <Grid container className={classes.section} spacing={3} alignItems="center">
        <Grid item xs={12}>
          <FormLabel component="legend">Pasajeros</FormLabel>
          <Typography variant={"body2"}>{"Escriba la cantidad de personas de acuerdo según corresponda a su grupo. Para cada grupo seleccione la provincia o país de procedencia de la mayoría de personas en su grupo. Debe de incluir todas las personas, incluyendo choferes o guías"}</Typography>

        </Grid>
      </Grid>
      <Pasajeros
        pasajeros={pasajeros}
        setPasajeros={setPasajeros}
        diaDeCobro={diaDeCobro}
        controlCamping={controlCamping}
        nochesCamping={nochesCamping}
        calcularNochesCamping={calcularNochesCamping}
        setCantidadTotal={setCantidadTotal}
      />
      <PasajerosCamping
        controlCamping={controlCamping}
        pasajeros={pasajeros}
        cantidadTotal={cantidadTotal}
        subtotal={subtotalCamping}
        setSubtotal={setSubTotalCamping}
        nochesCamping={nochesCamping}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {(props.pago === "Transferencia") ? (
            <FormLabel component="legend">{"Pago de su reservación"}</FormLabel>
          ) : (
            <Typography variant={"body1"}>{"Al llegar al Parque debe pagar su admisión con tarjeta, aplicará el tipo de cambio del día."}</Typography>
          )}
        </Grid>
        <NumerosDeCuenta
          pago={props.pago}
          sector={props.sector}
        />

        <Grid item xs={12} md={6}>
          {desglosePagos.map((pago, index) => {
            return (
              <Grid key={index} item xs={12} container justifyContent={"flex-end"}>
                <Grid item xs={9}>
                  <Typography variant={"body1"}>{pago.label}</Typography>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  <Typography variant={"body1"}>
                    <strong> {pago.value}</strong>
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>

      </Grid>

      {(props.pago === "Transferencia") && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={"body2"}>
              {"Digite el número de deposito y adjunte la confirmación de deposito de su banco."}
            </Typography>
          </Grid>


          <Grid item xs={6} md={6}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={"comprobante"} id="comprobante-label">{"Adjuntar comprobante (Solo pdf o imagen, máximo 10MB)"}</InputLabel>
              <input accept="image/*, application/pdf"
                id="comprobante"
                type="file"
                multiple={true}
                onChange={(e) => {
                  let files = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    files.push(e.target.files[i]);
                  }
                  setComprobante(files)
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        Documentos adjuntos:
        <ul>
          {comprobantesActuales.map((attachment, index) => {

            return (
              <li key={index}><
                a href={attachment.url} target={"_blank"} rel={"noreferrer"}> {attachment.file}</a>
                <label htmlFor="icon-button-file">
                  <IconButton aria-label={"Borrar pasajero"} component="span"
                    onClick={() => {
                      setComprobantesActuales(currentComprobantesActuales => currentComprobantesActuales.filter(x => x !== attachment))
                    }}
                  >
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </label>
              </li>)
          })}


        </ul>
      </Grid>
      <Grid item xs={6}>
        <Detalles
          inputFields={inputFields}
          setInputFields={setInputFields}
          position={"bottom"}
          width={6}
          setCharla={setCharla}
          setComprobante={setComprobante}
        />
      </Grid>


      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Button
            variant="contained"
            color="primary"
            type={"Submit"}
            disabled={disabledSubmit}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit()
            }}
            startIcon={<SendIcon />}
          >Enviar
          </Button>
          {loading ? <div><CircularProgress /> Enviar</div> : null}
        </Grid>


      </Grid>

    </>
  )
}

const Detalles = (props) => {
  const classes = useStyles();
  Detalles.defaultProps = {
    width: 4,
    charla: "No"
  }
  return (
    <Grid container spacing={3}>
      {props.inputFields.map((item, index) => {
        if (item.position === props.position) {
          if (item.type === "exoneracion") {
            return (
              <Grid item container xs={12} key={index}>
                <Grid item xs={6} md={props.width} >
                  <FormLabel component="legend">¿Tiene una resolución de exoneración?</FormLabel>
                  <RadioGroup row
                    label="¿Tiene una resolución de exoneración?"
                    name={"exonerado"}
                    value={item.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      props.setInputFields(currentInputFields =>
                        produce(currentInputFields, v => {
                          v[index].value = value;
                        }))
                    }}
                  >
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item sm={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor={"comprobante"} id="comprobante-label">Adjuntar comprobante (Solo pdf, máximo 10MB)</InputLabel>
                    <input accept="application/pdf"
                      disabled={(item.value !== "Si")}
                      id="comprobante"
                      type="file"
                      multiple={true}
                      onChange={(e) => {
                        let files = [];
                        for (let i = 0; i < e.target.files.length; i++) {
                          files.push(e.target.files[i]);
                        }
                        props.setComprobante(files)
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}> Escuelas y colegios ubicados en distritos con menor <a href={"https://www.mideplan.go.cr/indice-desarrollo-social"} target={"_blank"} rel={"noreferrer"}>índice de desarrollo relativo</a> según MIDEPLAN  pueden solicitar la exoneración de cobro. <a href={"#"}> Para ello consulte el proceso en este enlace</a></Grid>
              </Grid>
            )
          }
          if (item.type === "charla") {
            return (
              <Grid item container xs={12} key={index}>
                <Grid item xs={6} md={props.width} >

                  <FormLabel component="legend">{item.label}</FormLabel>
                  <RadioGroup row
                    aria-label={item.label}
                    name={item.name}
                    value={item.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      props.setInputFields(currentInputFields =>
                        produce(currentInputFields, v => {
                          v[index].value = value;
                        }))
                      props.setCharla(value)
                    }}
                  >
                    <FormControlLabel
                      value={"Si"}
                      control={<Radio />}
                      label="Si"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>

                </Grid>
                <Grid item xs={3} md={props.width} key={index}>
                  <FormLabel component="legend">Hora de la Charla</FormLabel>
                  <RadioGroup row
                    disabled={(props.charla === "No")}
                    required={(props.charla === "No")}
                    label="Hora de la Charla"
                    name={item.name}
                    value={item.hora}
                    onChange={(e) => {
                      const value = e.target.value;
                      props.setInputFields(currentInputFields =>
                        produce(currentInputFields, v => {
                          // v[index].value = "Si";
                          v[index].hora = value
                        }))
                    }}
                  >

                    <FormControlLabel
                      value="9:00 am"
                      disabled={(props.charla === "No")}
                      control={<Radio />}
                      label="9:00 am"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="1:00 pm"
                      disabled={(props.charla === "No")}
                      control={<Radio />}
                      label="1:00pm"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Typography variant={"body2"}>
                    Las charlas tienen un costo de $27 y son sujetas a disponibilidad de personal el
                    día de su visita. En los próximos tres días hábiles se le confirmará la
                    disponibilidad de charla para su fecha.
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          if (item.type === "email" || item.type === "text") {
            return (
              <Grid item xs={6} md={props.width} key={index}>
                <TextField type={item.type} name={item.name}
                  label={item.label} fullWidth
                  required={item.required}
                  value={props.inputFields[index].value}
                  onChange={(e) => {
                    const value = e.target.value;
                    props.setInputFields(currentInputFields =>
                      produce(currentInputFields, v => {
                        v[index].value = value;
                      }))
                  }}
                />
              </Grid>
            )
          }
        }
        return (<div key={index}></div>)
      })
      }
    </Grid>
  )

}

const FormErrors = (props) => {
  const errores = props.errors;
  return (
    <>
      {(errores !== null && errores.length > 0) && (
        <Grid container alignItems="center" style={{ backgroundColor: "rgb(253, 236, 234)", padding: "1rem" }}>
          <Grid item xs={1}>
            <ReportProblemIcon style={{ color: "#ad1d1d" }} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography variant={"body2"} style={{ color: "#ad1d1d" }}>
              {errores.map((error, index) => {
                return (
                  (index > 1 ? ', ' : '') + error
                )
              })
              }
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const NumerosDeCuenta = (props) => {

  const classes = useStyles();
  if (props.pago === "Transferencia") {
    if (props.sector === "Horizontes") {
      return (
        <>
          <Grid item xs={12} md={5}>
            <Typography className={classes.displayLinebreak} variant={"body2"}>
              {"Para poder confirmar su reservación debe de depositar el total indicado, según el tipo de moneda (¢ o $) que vaya a realizar el depósito en alguna de las siguientes cuentas. Indicar en el detalle del depósito:"} "Visita al Sector {props.sector}, ACG"
              <br /><br />
              {"Cuenta en colones:"} <br />
              Banco Nacional de Costa Rica <br />
              Número de cuenta: 100-01-080-006225-2 <br />
              Cuenta IBAN: CR94015108010010062250<br />
              Nombre: Fundación de Parques Nacionales<br />
              Cédula jurídica: 3-006-045912 <br /><br />
              Cuenta en dólares <br />
              Banco Nacional de Costa Rica <br />
              Número de cuenta: 100-02-080-602240-1 <br />
              Cuenta IBAN: CR04015108010026022407 <br />
              Nombre:  Fundación de Parques Nacionales<br />
              Cédula jurídica: 3-006-045912 <br />
              SWIFT: BNCRCRSJ <br />
            </Typography>
          </Grid>
          <Grid item md={1} display={{ xs: 'none' }}>
          </Grid>
        </>
      )
    } else {
      return (
        <>
          <Grid item xs={12} md={5}>
            <Typography className={classes.displayLinebreak} variant={"body2"}>
              Para poder confirmar su reservación debe de depositar el total indicado, según el tipo de moneda (¢ o $) que vaya a realizar el depósito en alguna de las siguientes cuentas. Indicar en el detalle del depósito: "Visita al Sector {props.sector}, ACG"
              <br /><br />
              Cuenta en colones: <br />
              Banco Nacional de Costa Rica <br />
              Número de Cuenta: 100-01-000-041220-5 <br />
              Cuenta IBAN: CR15 0151 0001 0010 4122 05 <br />
              Nombre: SINAC FONDO DE PARQUES NACIONALES <br />
              Cédula Jurídica: 3-007-317912 <br />
              <br />
              Cuenta en dólares: <br />
              Banco Nacional de Costa Rica <br />
              Número de CuentaL 100-02-000-605009-0 <br />
              Cuenta IBAN: CR56 0151 0001 0026 0500 98 <br />
              Nombre:SINAC FONDO DE PARQUES NACIONALES <br />
              Cédula Jurídica: 3-007-317912 <br />
            </Typography>
          </Grid>
          <Grid item md={1} display={{ xs: 'none' }}>
          </Grid>
        </>
      )
    }
  }
  else {
    return (
      <Hidden mdDown>
        <Grid item md={6}>
          <br />
        </Grid>
      </Hidden>


    )
  }
}

export default FormularioPrueba

