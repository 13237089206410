import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/layout";
import Login from "../../components/Login";
import {Reservaciones} from "../../components/reservaciones";
import PrivateRoute from "../../components/privateRoute";
import Report from "../../components/report";
import UnaReservacion from "../../components/unaReservacion";
import Edit from "../../components/edit";
import {Escuelas} from "../../components/escuelas";

const App = () => {
    return (
        <Layout>
            <Router basepath="/app">
                <Login path="/login" />
                <PrivateRoute path="/reservaciones" component={Reservaciones} />
                <PrivateRoute path="/escuelas" component={Escuelas} />
                <PrivateRoute path={"/report"} component={Report} />
                <PrivateRoute path={"/reservacion"} component={UnaReservacion} />
                <PrivateRoute path={"/edit"} component={Edit} />
            </Router>
        </Layout>
    )
}

export default App
