import React, { useState, useEffect } from "react";
import {
    Grid,
    Table, TableHead, TableBody, TableCell, TableRow,
    Paper, Button
} from "@material-ui/core";
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useQueryParam, Reservacion } from "use-query-params";
import axios from "axios"
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';
import { red, indigo } from '@material-ui/core/colors';

import BuscarReservacion from "./buscarReservacion";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from '@material-ui/icons/Edit';
import { navigate } from "gatsby";

const UnaReservacion = () => {
    return (
        <>
            <ViewReservacion />
        </>
    )

}


const useStyles = makeStyles(() => ({
    some: {
        color: "inherit"
    },
    some2: {
        color: "inherit"
    },
    some3: {
        color: "inherit"
    },
    some4: {
        color: "inherit"
    },

    codigoRes: {
        padding: "1rem",
        backgroundColor: "rgb(211,47,47)",
        color: "white"
    },
    header: {
        paddingTop: "0.5rem"
    },
    gutterBottom: {
        paddingBottom: "0.5rem"
    }
}));

const actionsTheme = createTheme({
    palette: {
        primary: { main: red[900] },
        secondary: { main: indigo[500] }
    },
});

const ViewReservacion = () => {
    let [reservacion] = useQueryParam("reservacion", Reservacion)
    const [data, setData] = useState([])
    const [detalles, setDetalles] = useState([]);
    const [sectores, setSectores] = useState([])
    const [pasajeros, setPasajeros] = useState([])
    const [desglosePagos, setDesglosePagos] = useState([])
    const [comprobantes, setComprobantes] = useState([])
    const [camping, setCamping] = useState(null);
    const classes = useStyles();
    const [error, setError] = useState("")


    useEffect(() => {
        async function getReservation() {
            setError("")
            axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php?action=oneReservation&reservacion=' + reservacion)
                .then(function (response) {
                    const info = response.data
                    if (typeof info.error != "undefined") {
                        setError("No se ha podido encontrar una reservación con el número: " + reservacion)
                    } else {
                        if (Object.keys(info).length > 0) {
                            setData(info);
                            setDetalles(info.detalles);
                            let destinos = {};
                            setSectores(destinos);
                            setPasajeros(info.pasajeros)
                            setDesglosePagos(info.desglosePagos)

                            if (info.camping !== "undefined" && info.camping === true) {
                                let paxCamping = {
                                    fechaSalida: info.fechaSalida,
                                    paxCamping: info.paxCamping,
                                    nochesCamping: info.nochesCamping
                                }

                                setCamping(paxCamping);


                            }
                        } else {
                            setError("No se ha podido encontrar una reservación con el número: " + reservacion)
                        }


                        typeof info.comprobantes != "undefined" ? setComprobantes(info.comprobantes) : setComprobantes([])
                    }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                        setData([])
                    } else {
                        setError("Hubo un error al conseguir la información de la reserva. " + error)
                        setData([])
                    }
                })
        }
        if (typeof reservacion !== 'undefined')
            getReservation()
    }, [reservacion]);

    const handleDelete = (reservacion) => {
        axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php?action=oneReservation&reservacion=' + reservacion).then((response) => {

            if (typeof window !== "undefined") {
                const answer = window.confirm("¿Desea borrar la reservación " + reservacion + " ?")
                if (answer) {
                    borrarReservacion(reservacion)
                }
            }

        })
    }

    async function borrarReservacion(reservacion) {

        const allData = new FormData();
        allData.append('reservacion', reservacion)
        allData.append('action', 'deleteReservacion')
        setError("")
        await axios.post(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php', allData)
            .then(function (response) {
                const info = response.data

                if (info.error !== "") {
                    navigate("/app/reservaciones")
                } else {
                    setError(info.error)
                }

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                    setData([])
                } else {
                    setError("Hubo un error al conseguir la información de la reserva. " + error)
                    setData([])
                }
            })
    }

    if (typeof reservacion === 'undefined') {
        return (
            <BuscarReservacion />
        )
    }
    if (error !== "") {
        return (
            <>
                <Alert severity="error">{error}</Alert>
                <Grid container>
                    <Grid item md={12}>
                        <>
                            <BuscarReservacion />
                        </>
                    </Grid>
                </Grid>
            </>
        )
    }
    return (
        <Grid container>
            <Grid item xs={12} container id={"123xx"}>
                <Grid item container alignItems={"center"} justifyContent={"space-between"} md={12} xs={12}>
                    <div>
                        <ThemeProvider theme={actionsTheme}>
                            <Button
                                variant="contained"
                                color={"secondary"}
                                onClick={() => {
                                    navigate("/app/edit?reservacion=" + reservacion);
                                }}
                                startIcon={<EditIcon />}
                            >
                                Editar
                            </Button>&nbsp;|&nbsp;
                            <Button
                                variant="contained"
                                color={"primary"}
                                onClick={() => {
                                    handleDelete(reservacion)
                                }}
                                startIcon={<DeleteForeverIcon />}
                            >
                                Borrar
                            </Button>
                        </ThemeProvider>
                    </div>
                    <div className={classes.gutterBottom}>
                        <BuscarReservacion />
                    </div>
                </Grid>
                <Grid item container>
                    <Grid item md={10} xs={12}>
                        {(data.tipo !== "escuela") ? (
                            <Typography variant="h5" component="h3" className={classes.header} gutterBottom>
                                Reservación de ingreso al Sector {data.sector}.
                            </Typography>
                        ) :
                            (
                                <Typography variant="h5" component="h3" className={classes.header} gutterBottom>
                                    Reservación departe de un centro educativo para el Sector {data.sector}.
                                </Typography>
                            )}

                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Paper elevation={3} className={classes.codigoRes}>
                            <Typography variant="h6">
                                {data.reservacion}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        Detalles de la reservación:
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <ul>
                        <li>
                            <Typography variant="body2" gutterBottom>
                                <strong>{"Fecha: "}</strong>
                                {data.fechaS}
                            </Typography>
                        </li>
                        {detalles.map((field, index) => {
                            const hora = (typeof field.hora !== 'undefined') ? " " + field.hora : ""
                            if (field.name !== 'fecha') {
                                return (
                                    <li key={index}>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>{field.label + " "}</strong>
                                            {field.value + hora}
                                        </Typography>
                                    </li>
                                )
                            }

                        })}
                        {camping !== null ?
                            <li>
                                <Typography variant="body2" gutterBottom>
                                    <strong>{"Fecha Salida "}</strong>
                                    {camping.fechaSalida}
                                </Typography>
                            </li>
                            :
                            <></>
                        }
                        {camping !== null ?
                            <li>
                                <Typography variant="body2" gutterBottom>
                                    <strong>{"Pasajeros a acampar "}</strong>
                                    {(camping.paxCamping / 4) / camping.nochesCamping}
                                </Typography>
                            </li>
                            :
                            <></>
                        }
                        {camping !== null ?
                            <li>
                                <Typography variant="body2" gutterBottom>
                                    <strong>{"Noches reservadas "}</strong>
                                    {camping.nochesCamping}
                                </Typography>
                            </li>
                            :
                            <></>
                        }
                    </ul>
                </Grid>

                {sectores.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Sectores a Visitar
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <ul>
                                {sectores.map((field, index) => {

                                    return (
                                        <li key={index}>
                                            <Typography variant="body2" gutterBottom>
                                                <strong>{field}</strong>
                                            </Typography>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Cantidad</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>País</TableCell>
                                <TableCell>Provincia</TableCell>
                                <TableCell>Tarifa</TableCell>
                                <TableCell>Costo</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {pasajeros.map((row, index) => (
                                ((!isNaN(row.cantidad) && row.cantidad > 0) || isNaN(row.cantidad)) && (
                                    <TableRow key={index} scope="row">
                                        <TableCell>{(row.cantidad || 1)}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.nombrePax}
                                        </TableCell>

                                        <TableCell>{row.nacional}</TableCell>
                                        <TableCell>{row.provincia}</TableCell>
                                        <TableCell>{row.tarifa}</TableCell>
                                        <TableCell>{row.moneda + row.costo}</TableCell>
                                        <TableCell>{row.subtotal}</TableCell>
                                    </TableRow>)

                            ))}

                            {(camping !== null) ?
                                <TableRow key={0} scope="row">
                                    <TableCell>
                                        {(camping.paxCamping / 4) / camping.nochesCamping}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Acampar
                                    </TableCell>

                                    <TableCell>Costa Rica</TableCell>
                                    <TableCell>Guanacaste</TableCell>
                                    <TableCell>General</TableCell>
                                    <TableCell>4$</TableCell>
                                    <TableCell>
                                        {camping.paxCamping}
                                    </TableCell>
                                </TableRow>
                                :
                                <></>
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                {desglosePagos.map((pago, index) => {
                    return (
                        <Grid key={index} item xs={12} container justifyContent={"flex-end"}>
                            <Grid item xs={8}>
                                <Typography variant={"body2"}>{pago.label}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant={"body2"}>
                                    <span style={{ textAlign: "right" }}><strong>  {pago.value}</strong></span>
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            {comprobantes.length > 0 && comprobantes.map((adjunto, index) => {
                return (
                    <Grid item container key={index}>
                        <Grid item xs={8}>
                            {(data.tipo !== "escuela") ? (
                                <Typography variant={"body2"}>Comprobante de pago</Typography>
                            ) :
                                (
                                    <Typography variant={"body2"}>Documento Adjunto</Typography>
                                )}
                        </Grid>
                        <Grid key={index} item xs={12} container>
                            <Grid item>
                                <a href={adjunto.url} target={"_blank"} rel={"noreferrer"}>{adjunto.file}</a>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default UnaReservacion;
