import React, {useState, useEffect, useRef} from "react"
import Layout from "../components/layout";
import {
    TextField,
    Grid,
    IconButton,
    Select,
    RadioGroup,
    Radio,
    Button,
    FormControlLabel,
    FormLabel,
    makeStyles,
    InputLabel,
    Hidden,
    Checkbox,
    FormControl, MenuItem
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import DatePicker from 'react-date-picker';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CircularProgress from '@material-ui/core/CircularProgress';
import {produce} from "immer";
import {generate} from 'shortid'
import axios from 'axios';
import {navigate} from 'gatsby'
import {
    sectoresMarino,
    costosSantaRosa,
    pasajerosSR,
    inputsMarino,
    horasMarino,
    settingsSR,
    feriados,
    defaultMetodoDePago
} from "../components/defaults"
import {countries, provincias} from "../components/paises";
import DisponibilidadMarino from "../components/disponibilidadMarino"
import "./date.css"

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        display: "flex"
    },
    displayLinebreak: {
        whiteSpace: "pre-line"
    },
    section: {
        marginTop: "1rem"
    },
    calendar: {
        border: "none"
    },
    extraTop :{
        paddingTop: "0.5 rem"
    }
    , head: {
        backgroundColor: "rgb(211,47,47)",
        color: "white"
    }
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Marino = (props) => {
    const sectorACG = "Marino";
    const classes = useStyles();
    const [reservationId, setReservationId] = useState();
    const [sectores, setSectores] = useState(sectoresMarino);
    const [costos] = useState(costosSantaRosa);
    const [horas, setHoras] = useState(horasMarino)
    const [inputFields, setInputFields] = useState(inputsMarino);
    const [submited, setSubmited] = useState(false)
    let [errorMsg, setErrorMsg] = useState(null)
    let [data, setData] = useState(null);
    const [total, setTotal] = useState(1100);
    const [desglosePagos, setDesglosePagos] = useState([]);
    const [cuposDisponibles, setCuposDisponibles] = useState(0);
    
    const [pasajeros, setPasajeros] = useState(pasajerosSR);
    const [paxCount, setpaxCount] = useState(1);
    const [disabledAddPax, setDisabledAddPax] = useState(false);
    const [disabledSubmit, setDisableSumbit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [comprobante, setComprobante] = useState([]);
    const [comprobantesActuales, setComprobantesActuales] = useState([]);
    const [metodoPago, setMetodoPago] = useState(defaultMetodoDePago)
    
    let minDateDate = new Date();
    minDateDate.setDate(minDateDate.getDate() + settingsSR.diasPrevios);
    let maxiDate = new Date ();
    maxiDate.setDate(minDateDate.getDate()+31)
    const [maxDate, setMaxDate] = useState(maxiDate);
    
    
    const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
    const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(minDateDate);
    const fechaFormated = (`${year}-${month}-${day}`)
    
    const [fechaR, setFechaR] = useState(fechaFormated);
    const [fecha, setFecha] = useState(minDateDate);
    const [condiciones, setCondiciones]=useState(false)
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const [currentPax, setCurrentPax] = useState(0);
    const [currentActividad, setCurrentActividad] = useState("Surf");
    const [currentDate, setCurrentDate] = useState(fechaFormated)
    
    //Post del formulario al servidor
    useEffect(() => {
        async function sendReservation() {
            if (data !== null) {
                setLoading(true)
                const allData = new FormData();
                if (comprobante !== null){
                    comprobante.forEach(file=>{
                        allData.append(generate(), file);
                    });
                }
                allData.append('data',JSON.stringify(data))
                 axios.post(process.env.GATSBY_REMOTE_SERVER + "doReserve.php", allData)
                    .then((response) => {
                        // console.log(response);
                        if (response.data === 'Success') {
                            navigate("/app/reservacion?reservacion=" + reservationId, {
                                state: {
                                    data
                                }
                            })
                            setLoading(false)
                        } else {
                            setErrorMsg(null);
                            let error = []
                            error.push("Ha habido un error haciendo su reservación . "+response.data);
                            setSubmited(false);
                           
                            setLoading(false);
                            setErrorMsg(error);
                            executeScroll()                        }
                    })
            }
        }
        sendReservation();
    }, [data]);
    
    useEffect( () => {
        //console.log("props.d", props.data)
        if (props.data.reservacion){
            setReservationId(props.data.reservacion)
            setInputFields(props.data.detalles)
            setSectores(props.data.sectores)
            setPasajeros(props.data.pasajeros)
            setCurrentPax(props.data.pasajeros.length)
            setCurrentActividad(props.data.detalles[11].value)
            const timestamp = Date.parse(props.data.fechaS+"T00:00:00-0600")
            setTheDate(new Date(timestamp))
            setCurrentDate(props.data.fechaS)
            setMetodoPago(props.data.pago)
            setDesglosePagos(props.data.desglosePagos)
            setComprobantesActuales(props.data.comprobantes)
        }
    },[])

    const getTipoDeCambio = TipoDeCambio;
    var  [tipoDeCambio, setTipoDeCambio] = useState(getTipoDeCambio);
    //Calculo de costos
    useEffect(() => {
        let isSubscribed = true;
        
        async function recalcularCosto() {
            if (isSubscribed) {
                
                await setTotal(0)
                const subTDolares = pasajeros.reduce(function calcSTD(accumulator, pax) {
                    return (pax.moneda === '$') ? accumulator + pax.costo : accumulator;
                }, 0)
                const subTNacionalesLocal = pasajeros.reduce(function calcSTN(accumulator, pax) {
                    return (pax.moneda === "₡") ? accumulator + pax.costo : accumulator;
                }, 0)
                
                const ivaDolares = subTDolares * 0.13;
                const totalDolares = subTDolares + ivaDolares;
                const totalDolaresColonesLocal = totalDolares > 0 ? (totalDolares * tipoDeCambio) : 0;
                const iva = subTNacionalesLocal * 0.13;
                const SubtotalNacionalDisplay = subTNacionalesLocal ;
                const totalNacionales = subTNacionalesLocal + iva ;
                const totalColones = totalNacionales + totalDolaresColonesLocal
                const tdc = (typeof tipoDeCambio === 'number')?"(TC:"+tipoDeCambio.toString()+")":"";
                const formatColones = {maximumFractionDigits: 2,minimumFractionDigits:2, style: 'currency',currency: 'CRC'};
                const formatDolares = {maximumFractionDigits: 2,minimumFractionDigits:2, };
                const desglosePagos = [
                    {label: "Subtotal Extrajeros", value: "$"+subTDolares.toLocaleString('es-CR',formatDolares)},
                    {label: "IVA", value: "$"+ ivaDolares.toLocaleString('es-CR',formatDolares)},
                    {label: "Total Extrajeros", value: "$"+totalDolares.toLocaleString('es-CR',formatDolares)},
                    {label: "Subtotal Extranjeros en Colones" +tdc, value: totalDolaresColonesLocal.toLocaleString('es-CR',formatColones)},
                    {label: "", value: "____________"},
                    {label: "Subtotal Nacionales", value: SubtotalNacionalDisplay.toLocaleString('es-CR',formatColones)},
                    {label: "IVA", value: iva.toLocaleString('es-CR',formatColones)},
                    {label: "Total Nacionales", value: totalNacionales.toLocaleString('es-CR',formatColones)},
                    {label: "", value: "____________"},
                    {label: "Total", value: totalColones.toLocaleString('es-CR',formatColones)},
                ];
                
                setTotal(totalColones);
                await setDesglosePagos(desglosePagos);
            }
        }
        
        recalcularCosto(pasajeros, tipoDeCambio)
        return () => (isSubscribed = false);
    }, [pasajeros,tipoDeCambio])
    
    
    async function  TipoDeCambio() {
        try {
            return await axios.get('https://reservaciones.acguanacaste.ac.cr/getTC.php')
                .then(function (response) {
                    const info = response.data
                    // console.log("tcxx:",info,response)
                    setTipoDeCambio(info)
                    return info;
                })
        } catch (error) {
            throw new Error(error);
        }
    };

    
    
    /*
    Desactiva los botones de agregar pasajero y enviar en caso de no haber cupos.
     */
    const handleCuposChanges = (cupo) => {
        setCuposDisponibles(cupo)
        if (cupo === 0){
            setDisabledAddPax(true)
        }else if (cupo < 0 ){
            setDisabledAddPax(true)
            setDisableSumbit(true)
            //  alert("No hay espacios disponibles para la fecha seleccionada");
        }
        else{
            setDisabledAddPax(false);
            setDisableSumbit(false)
        }
    }
    
    
    
    //Validación
    const validateForm = () => {
        let valid = true;
        let errores = [];
        let tmpFecha = ""
        
        inputFields.forEach(function (field, index) {
            if (field.required && (typeof field.value == "undefined" || field.value.length < 1)) {
                errores.push(field.label)
                valid = false
            }
            if (field.name === 'fecha') {
                if (field.value > maxDate){
                    errores.push("Fecha mayor a 31 días")
                }
            }
        })
        
        pasajeros.forEach(function (pax, index) {
            if (pax.nombrePax.length < 1) {
                valid = false
                errores.push("Nombre de cada pasajero")
            }
            if (pax.nacional.length < 1) {
                valid = false
                errores.push("País de origen de cada pasajero")
            }
            
        })
        if (desglosePagos === null) {
            valid = false;
            errores.push("Categoría de pago");
        }
        if (inputFields[0].value <= new Date()) {
            valid = false;
            errores.push("Fecha de reservación mayor a hoy")
        }
        if (inputFields[0].value > maxDate) {
            valid = false;
            errores.push("Fecha mayor a 31 días")
        }
        if (metodoPago === "Transferencia"){
            if (comprobante.length + comprobantesActuales.length < 1 ){
                valid = false;
                errores.push("Debe de adjuntar el comprobante de depósito")
            }
        }
        if (!condiciones && inputFields[11].value == "Sendero Mirador Isla San José"){
            valid = false;
            errores.push("Debe aceptar las regulaciones de ingreso.")
        }
        if (errores.length > 0){
            errores.unshift("Los siguientes campos son requeridos: ")
            executeScroll()
        }
        
        setErrorMsg(errores)
        return valid;
    };
    
    const handleSubmit = () => {
        setErrorMsg(null);
        setData(null);
        const validForm = validateForm();
        const x = comprobantesActuales.map((value)=>{
            return value.file;
        })
        if (validForm && !submited) {
            setData([{
                sector: sectorACG,
                reservacion: reservationId,
                detalles: inputFields,
                sectores: sectores,
                pasajeros: pasajeros,
                fecha: fechaR,
                pago: metodoPago,
                desglosePagos,
                comprobantesActuales: x,
                action:"edit",
            }]);
            setSubmited(true);
        }
    }
    
    const resetForm = () => {
        setErrorMsg(null);
        setData(null);
        setPasajeros(pasajerosSR)
    }
    
    const setTheDate = (theDate) => {
       
        if (theDate > maxDate){
            alert("Acaba de escribir una fecha invalida")
        }
        setFecha(theDate)
        const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
        const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(theDate);
        const fechaFormated = (`${year}-${month}-${day}`)
        setFechaR(fechaFormated)
        setInputFields(currentInputFields =>
            produce(currentInputFields, v => {
                v[0].value = fechaFormated;
            }))
     
    }
    
    return (
        
        < >
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {"Sector Marino, reservación #: "}
                        <span className={classes.head}>{reservationId}</span>
                    </Typography>
                    
                </Grid>
            
            </Grid>
            <form className="form">
                <Grid container spacing={3} ref={myRef}>
                    <Grid item xs={12}>
                        <FormErrors  errors={errorMsg}/>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <FormLabel component="legend">{"Actividad a realizar:"}</FormLabel>
                    
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <RadioGroup row aria-label="actividad" name="sectoresVisita"  value={inputFields[11].value} onChange={(e) => {
                            const actividad = e.target.value;
                            setInputFields(currentInputFields =>
                                produce(currentInputFields, v => {
                                    v[11].value = actividad;
                                }))
                        }}>
                            {sectores.map((sector, index) => {
                                return (
                                    <FormControlLabel key={index} value={sector.value} control={<Radio />} label={sector.label } />
                                )
                            })
                            }
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}  md={3}>
                        
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="fecha" className={" MuiInputLabel-shrink MuiFormLabel-filled"}>Fecha del Viaje</InputLabel>
                            <DatePicker
                                id={"fecha"}
                                value={fecha}
                                locale={"es-CR"}
                                className={classes.calendar+ " MuiInputBase-root MuiInput-root MuiInput-underline  MuiInputBase-formControl MuiInput-formControl"}
                                onChange={(value) => {
                                    setTheDate(value)
                                }}
                                
                                minDate={minDateDate}
                                maxDate={maxDate}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="hora">{"Hora de llegada"}</InputLabel>
                            <Select
                                label={"Hora de llegada"}
                                native
                                className={classes.textField}
                                value={inputFields[1].value}
                                onChange={(e) => {
                                    const hora = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[1].value = hora;
                                        }))
                                }}
                                inputProps={{
                                    name: 'hora',
                                    id: 'hora',
                                    
                                }}
                            >
                                {horas.map((unaHora, index) => {
                                    return (<option key={index} value={unaHora.value}>{unaHora.label}</option>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={2}>
                        
                        </Grid>
                    </Hidden>
                    <Grid item sm={12} md={4}>
                        <DisponibilidadMarino sector={sectorACG}
                                              fecha={fechaR}
                                              paxCount={pasajeros.length}
                                              cuposDisponibles={cuposDisponibles}
                                              actividad={inputFields[11].value}
                                              onChange={handleCuposChanges}
                                              editPax={currentPax}
                                              editActivity={currentActividad}
                                              editDate={currentDate}
                                              
                        />
                    </Grid>
                
                </Grid>
                <Grid container spacing={3}>
                
                </Grid>
    
                <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                        <TextField type={"email"} name={"email"} label="Email" value={inputFields[2].value || ''} fullWidth required
        
                                   onChange={(e) => {
                                       const email = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[2].value = email;
                                           }))
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField type={"text"} name={"telefono"} label="Teléfono" value={inputFields[13]?.value || ''} fullWidth required
        
                                   onChange={(e) => {
                                       const telefono = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[13].value = telefono;
                                           }))
                                   }}
                        />
                    </Grid>
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                        <TextField name="embacacion" label={"Embarcación"} value={inputFields[3].value || ''} fullWidth required
                                   onChange={(e) => {
                                       const embarcacion = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[3].value = embarcacion;
                                           }))
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField name="matricula" label="Matrícula" value={inputFields[4].value || ''} fullWidth required
                                   onChange={(e) => {
                                       const matricula = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[4].value = matricula;
                                           }))
                                   }}
                        />
                    </Grid>
                    
                </Grid>
                
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} md={3}>
                        <TextField name="capitan" label="Capitán" fullWidth value={inputFields[5].value || ''} required
                                   onChange={(e) => {
                                       const capitan = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[5].value = capitan;
                                           }))
                                   }}
                        />
                    </Grid>
                    
                    
                    
                    <Grid item xs={5} md={3}>
                        
                        <FormControl className={classes.formControl}>
                            <InputLabel id="pais-label">País</InputLabel>
                            <Select
                                labelId="pais-label"
                                id="pais"
                                value={inputFields[6].value}
                                onChange={(e) => {
                                    const nacional = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[6].value = nacional;
                                        }))
                                }}
                            >
                                {countries.map((unPais, index) => {
                                    return (
                                        <MenuItem key={index} value={unPais.name}>{unPais.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="provincia-label">Provincia</InputLabel>
                            <Select
                                labelId="provincia-label"
                                id="provincia"
                                value={inputFields[7].value}
                                onChange={(e) => {
                                    const provincia = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[7].value = provincia;
                                        }))
                                }}
                            >
                                {provincias.map((unaProvincia, index) => {
                                    return (
                                        <MenuItem key={index} value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                
                </Grid>
                
                
                
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} md={3}>
                        <TextField name="marinero" label="Marinero" fullWidth
                                   value={inputFields[8].value || ''}
                                   onChange={(e) => {
                                       const capitan = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[8].value = capitan;
                                           }))
                                   }}
                        />
                    </Grid>
                    
                    
                    
                    <Grid item xs={5} md={3}>
                        
                        <FormControl className={classes.formControl}>
                            <InputLabel id="pais-label">País</InputLabel>
                            <Select
                                labelId="pais-label"
                                id="paisMarinero"
                                value={inputFields[9].value}
                                onChange={(e) => {
                                    const nacional = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[9].value = nacional;
                                        }))
                                }}
                            >
                                {countries.map((unPais, index) => {
                                    return (
                                        <MenuItem key={index} value={unPais.name}>{unPais.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="provincia-label">Provincia</InputLabel>
                            <Select
                                labelId="provincia-label"
                                id="provincia"
                                value={inputFields[10].value}
                                onChange={(e) => {
                                    const provincia = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[10].value = provincia;
                                        }))
                                }}
                            >
                                {provincias.map((unaProvincia, index) => {
                                    return (
                                        <MenuItem key={index} value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                
                </Grid>
                
                
                
                
                <Grid container className={classes.section}  spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <FormLabel component="legend">Pasajeros</FormLabel>
                        <Typography variant={"body2"}>{"Debe de incluir todas las personas del grupo, incluyendo guías y demás tripulación."}</Typography>
                    </Grid>
                    
                    <Grid item xs={12}>
                        {inputFields[11].value == "Sendero Mirador Isla San José" &&
                        <Alert severity="warning">
                            {"Al momento de hacer las reservación es obligatorio indicar correctamente los nombres de todos los integrantes de grupo que visita la Isla. El funcionario en la Isla verificará la lista e identificación antes de ingresar. La persona que no esté en la lista no puede ingresar."}
                        </Alert>
                        }
                    
                    </Grid>
                </Grid>
                
                
                {pasajeros.map((field, index) => {
                    // console.log("renderx")
                    return (
                        <Grid key={field.id} container spacing={3}>
                            <Grid item xs={6} md={3}>
                                <TextField name="nombre" label="Nombre" required value={field.nombrePax} fullWidth
                                           onChange={(e) => {
                                               const nombre = e.target.value;
                                               setPasajeros(currentPasajeros =>
                                                   produce(currentPasajeros, v => {
                                                       v[index].nombrePax = nombre;
                                                   }))
                                           }}
                                />
                            </Grid>
                            
                            <Grid item xs={5} md={3}>
                                
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="pais-label">País</InputLabel>
                                    <Select
                                        labelId="pais-label"
                                        id="pais"
                                        value={field.nacional}
                                        onChange={(e) => {
                                            const nacional = e.target.value;
                                            setPasajeros(currentPasajeros =>
                                                produce(currentPasajeros, v => {
                                                    v[index].nacional = nacional;
                                                    if (nacional !== "Costa Rica"){
                                                        v[index].tarifa = "adultoExtranjero";
                                                        v[index].costo = 15;
                                                        v[index].moneda = "$";
                                                    }else{
                                                        v[index].tarifa = "adulto";
                                                        v[index].costo = 1100;
                                                        v[index].moneda = "₡";
                                                    }
                                                }))
                                        }}
                                    >
                                        {countries.map((unPais, index) => {
                                            return (
                                                <MenuItem key={index} value={unPais.name}>{unPais.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            
                            </Grid>
                            <Grid item md={2}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="provincia-label">Provincia</InputLabel>
                                    <Select
                                        labelId="provincia-label"
                                        id="provincia"
                                        value={field.provincia}
                                        onChange={(e) => {
                                            const provincia = e.target.value;
                                            setPasajeros(currentPasajeros =>
                                                produce(currentPasajeros, v => {
                                                    v[index].provincia = provincia;
                                                }))
                                        }}
                                    >
                                        {provincias.map((unaProvincia, index) => {
                                            return (
                                                <MenuItem key={index} value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} md={1}>
                                <label htmlFor="icon-button-file">
                                    <IconButton aria-label={"Borrar pasajero"} component="span"
                                                onClick={() => {
                                                    setPasajeros(currentPasajeros => currentPasajeros.filter(x => x.id !== field.id))
                                                    setpaxCount(pasajeros.length)
                                                }}
                                    >
                                        <DeleteForeverIcon color="error"/>
                                    </IconButton>
                                </label>
                            </Grid>
                            <Grid item xs={12} md={11}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Tarifa</FormLabel>
                                    <RadioGroup row aria-label="tarifa" name="costo" value={field.tarifa}
                                                onChange={(e) => {
                                                    const tarifa = e.target.value;
                                                    const infoTarifa = costos.filter((unaCategoria) => {
                                                        return (unaCategoria.name === tarifa)
                                                    })
                                                    let costo = infoTarifa[0].value;
                                        
                                                    setPasajeros(currentPasajeros =>
                                                        produce(currentPasajeros, v => {
                                                            v[index].tarifa = tarifa;
                                                            v[index].costo = costo;
                                                            v[index].moneda = infoTarifa[0].labelCurrency
                                                        }));
                                                }}>
                                        
                                        {
                                            costos.map((unaCategoria, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={unaCategoria.name}
                                                        value={unaCategoria.name}
                                                        control={<Radio/>}
                                                        label={unaCategoria.label}
                                                    
                                                    />
                                                )
                                            })}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                {field.moneda + field.costo}
                            </Grid>
                        </Grid>
                    
                    )
                })}
                
                
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={disabledAddPax}
                            onClick={() => {
                                setPasajeros(currentPasajeros => [...currentPasajeros, {
                                    id: generate(),
                                    nombrePax: "",
                                    nacional: "",
                                    provincia: "",
                                    costo: 1100,
                                    tarifa: "adulto",
                                    moneda: "₡"
                                }])
                                setpaxCount(pasajeros.length)
                            }}
                            
                            startIcon={<AddCircleIcon/>}
                        >Persona
                        </Button>
                    </Grid>
                
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormLabel component="legend">{"Pago de su reservación"}</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography className={classes.displayLinebreak} variant={"body2"}>
                            {"Para poder confirmar su reservación debe de depositar el total indicado, según el tipo de moneda (¢ o $) que vaya a realizar el depósito en alguna de las siguientes cuentas. Indicar en el detalle del depósito:"} "Visita al Sector {sectorACG}, ACG"
                            
                            <br /><br />
                            Cuenta en Colones:<br /> Banco Nacional de Costa Rica<br />
                            Cuenta Banco Nacional: 100-01-000-041220-5 <br />
                            Cuenta IBAN: CR15 0151 0001 0010 4122 05 <br />
                            SINAC FONDO DE PARQUES NACIONALES  <br />
                            Cédula Jurídica: 3-007-317912 <br /><br />
                            Cuenta en dólares <br />
                            Cuenta Banco Nacional: 100-02-000-605009-0<br />
                            Cuenta IBAN: CR56 0151 0001 0026 0500 98 <br />
                            SINAC FONDO DE PARQUES NACIONALES.<br />
                            Cédula Jurídica: 3-007-317912
                        
                        </Typography>
                    </Grid>
                    <Grid item md={1} display={{xs: 'none'}}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {desglosePagos.map((pago, index) => {
                            return (
                                <Grid key={index} item xs={12} container justifyContent={"flex-end"}>
                                    <Grid item xs={9}>
                                        <Typography variant={"body1"}>{pago.label}</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign: "right"}}>
                                        <Typography variant={"body1"}>
                                            <strong> {pago.value}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                
                
                </Grid>
                
                
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            {"Digite el número de deposito y adjunte la confirmación de deposito de su banco."}
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={6} md={4}>
                        <TextField name="motivo" label={"Depósito Número:"} fullWidth required
                                   value={inputFields[12].value || ''}
                                   onChange={(e) => {
                                       const desposito = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[12].value = desposito;
                                           }))
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor={"comprobante"} id="comprobante-label">{"Adjuntar comprobante (Solo pdf o imagen, máximo 10MB)"}</InputLabel>
                            <input accept="image/*, application/pdf"
                                   id="comprobante"
                                   type="file"
                                   multiple={true}
                                   onChange={(e) => {
                                       let files = [];
                                       for (let i=0; i< e.target.files.length; i++){
                                           files.push(e.target.files[i]);
                                       }
                                       setComprobante(files)
                                   }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        Comprobantes de depósito adjuntados:
                        <ul>
                            {comprobantesActuales.map((attachment, index    ) => {

                                return (
                                    <li key={index}><
                                        a href={attachment.url} target={"_blank"} rel={"noreferrer"}> {attachment.file}</a>
                                        <label htmlFor="icon-button-file">
                                            <IconButton aria-label={"Borrar pasajero"} component="span"
                                                        onClick={() => {
                                                            setComprobantesActuales(currentComprobantesActuales => currentComprobantesActuales.filter(x => x !== attachment))
                                                        }}
                                            >
                                                <DeleteForeverIcon color="error"/>
                                            </IconButton>
                                        </label>
                                    </li>)
                            })}


                        </ul>
                    </Grid>
                </Grid>
                
                {(inputFields[11].value == "Sendero Mirador Isla San José") && (
                    <Grid item xs={12 } >
                        <FormControlLabel
                            control={<Checkbox checked={condiciones} onChange={(e)=>{setCondiciones(e.target.checked)}} name="gilad" />}
                            label={<span>He leído, entiendo y acepto las <a href={"https://www.acguanacaste.ac.cr/images/documentos/Isla-San-Jose-Regulaciones-caminata-DASP-ACG.pdf"} target="_blank">Regulaciones e indicaciones básicas para el ingreso al mirador en Isla San José</a></span>}
                            
                        > </FormControlLabel>
                    
                    </Grid>
                )}
                
                <Grid container spacing={3}>
                    <Grid item xs={6} md={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            type={"Submit"}
                            disabled={disabledSubmit || submited}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit()
                            }}
                            startIcon={<SendIcon/>}
                        >{"Enviar"}
                        </Button>
                        {loading ? <div><CircularProgress /> {"Espere mientras realizamos su reservación.!"}</div> :null}
                    </Grid>
                    
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                resetForm()
                            }}
                            startIcon={<RotateLeftIcon/>}
                        >Limpiar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

const FormErrors = (props) => {
    const errores = props.errors;
    return (
        <>
            {(errores !== null && errores.length > 0) && (
                <Grid container alignItems="center" style={{backgroundColor: "rgb(253, 236, 234)", padding: "1rem"}}>
                    <Grid item xs={1}>
                        <ReportProblemIcon style={{color: "#ad1d1d"}}/>
                    </Grid>
                    <Grid item xs={11} md={11}>
                        <Typography variant={"body2"} style={{color: "#ad1d1d"}}>
                            {errores.map((error, index) => {
                                return (
                                    (index>1 ? ', ' : '') + error
                                )
                            })
                            }
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default Marino
