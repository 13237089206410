import React, {useEffect, useState} from "react";
import {
    Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField,
    FormControl, makeStyles, Typography, IconButton
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import {Link, navigate} from "gatsby";
import axios from "axios"
import {observer} from "mobx-react-lite";
import {autorun} from 'mobx'

import {useFilterStore} from "../context/filter";
import BuscarReservacion from "./buscarReservacion";
import moment from "moment";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    escuela: {
        backgroundColor: "#a7cbe8"
    }
}));


export const Escuelas = observer(() => {
    Escuelas.defaultProps = {
        tipo: "turistas"
    }
    const [data, setData] = useState([])
    const filter = useFilterStore()
    const classes = useStyles();
    const showEdit = false;
    const action = "escuela";
    const [error, setError] = useState(null);

    async function getReservation() {
        await axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php', {
            params: {
                action,
                desde: filter.desde,
                hasta: filter.hasta,
                sort: "hora",
                direction: "desc"
            }
        })
            .then(function (response) {
                const info = response.data
                setData(info)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                } else {
                    setError("Hubo un error al conseguir la información de la reserva. " + error)
                }
            })
    }

    useEffect(() => {
        if (filter.tipo !== action) {
            let minDate = moment()
            minDate = moment(minDate).format('yyyy-MM-DD');
            let max = moment().add(1, 'month').format('yyyy-MM-DD');
            filter.setDesde(minDate)
            filter.setHasta(max)
            filter.setTipo(action)
        }
        getReservation()

    }, []);
    //hay que usar este autorun para que corra el efecto en el cambio del store
    useEffect(() => autorun(() => {
        getReservation()
    }), [filter.desde, filter.hasta]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={"h6"} component={"h1"}>
                    Reporte de reservaciones de Escuelas del {filter.displayDesde} a {filter.displayHasta}
                </Typography>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={6} md={3}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="date"
                            label="Fecha"
                            type="date"
                            format="dd/MM/yyyy"
                            fullWidth
                            required
                            defaultValue={filter.desde}
                            inputProps={{}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                const fecha = e.target.value;
                                filter.setDesde(fecha)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="date"
                            label="Fecha"
                            type="date"
                            format="dd/MM/yyyy"
                            fullWidth
                            required
                            defaultValue={filter.desde}
                            inputProps={{}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                const fecha = e.target.value;
                                filter.setDesde(fecha)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <BuscarReservacion/>
                </Grid>
            </Grid>
            {(error == null) ? null : (
                <Grid item container xs={12}>
                    <Grid item container alignItems="center"
                          style={{backgroundColor: "rgb(253, 236, 234)", padding: "1rem"}}>
                        <Grid item xs={1}>
                            <ReportProblemIcon style={{color: "#ad1d1d"}}/>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant={"body2"} style={{color: "#ad1d1d"}}>
                                {error}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <TablaReservaciones data={data} onAfterDelete={getReservation} showEdit={showEdit}/>
        </Grid>
    )
})

const TablaReservaciones = React.memo((props) => {
    const clases = useStyles();
    const [error, setError] = useState("");
    const handleDelete = (reservacion) => {
        if (typeof window !== "undefined") {
            const answer = window.confirm("¿Desea borrar la reservación " + reservacion + " ?")
            if (answer) {
                borrarReservacion(reservacion)
            }
        }


    }

    async function borrarReservacion(reservacion) {

        const allData = new FormData();

        allData.append('reservacion', reservacion)
        allData.append('username', reservacion)
        allData.append('action', 'deleteReservacion')
        setError("")
        await axios.post(process.env.GATSBY_BACKEND_SERVER+'endpoint.php', allData)
            .then(function (response) {
                const info = response.data

                if (info.error !== "") {
                    props.onAfterDelete()
                } else {
                    setError(info.error)
                }

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                } else {
                    setError("Hubo un error al borrar su reserva. " + error)
                }
            })
    }

    //
    return (
        <>
            {(error !== "") && (<Alert severity={"error"}>{error}</Alert>)}
            <Table
                aria-label="simple table"
                stickyHeader={true}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Reservación</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Escuela</TableCell>
                        <TableCell>Profesor</TableCell>
                        <TableCell>Pax</TableCell>
                        <TableCell>Charla</TableCell>
                        <TableCell>Hora</TableCell>
                        <TableCell>Borrar</TableCell>
                        {(props.showEdit) && (<TableCell>Editar</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.data.map((reservacion, index) => {
                        let deposito = reservacion.detalles.filter(input => input.name === 'centroEducativo')[0]
                        let charla = reservacion.detalles.filter(input => input.name === 'charla')[0]


                        let nombre = reservacion.detalles.filter(input => input.name === 'nombre')[0]
                        nombre = (typeof nombre != 'undefined' && nombre.value.length !== 0) ? nombre.value : reservacion.pasajeros[0].nombrePax

                        let cantidad = reservacion.pasajeros.reduce(function calcSTD(accumulator, pax) {
                            return accumulator + pax.cantidad;
                        }, 0)
                        if (isNaN(cantidad)) {
                            cantidad = reservacion.pasajeros.length;
                        }

                        //const clase = (reservacion.tipo === "escuela") ? clases.escuela : null;
                        return (
                            <TableRow key={index} >
                                <TableCell component="th" scope="row">
                                    <Link
                                        to={"/app/reservacion?reservacion=" + reservacion.reservacion}>{reservacion.reservacion}</Link>
                                </TableCell>
                                <TableCell>{reservacion.fechaS}</TableCell>
                                <TableCell>{deposito.value} </TableCell>

                                <TableCell>{nombre} </TableCell>
                                <TableCell>{cantidad}</TableCell>
                                <TableCell>{charla.value}</TableCell>
                                <TableCell>{reservacion.detalles[1].value + ":00"}</TableCell>
                                {(props.showEdit) && (
                                    <TableCell>{reservacion.detalles[11]?.value}</TableCell>)}
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            handleDelete(reservacion.reservacion)
                                        }}>
                                        <DeleteForeverIcon
                                            color="error"
                                        />
                                    </IconButton>
                                </TableCell>
                                {(props.showEdit) && (<TableCell>
                                    <IconButton
                                        onClick={() => {
                                            navigate("/app/edit?reservacion=" + reservacion.reservacion)
                                        }}>
                                        <EditIcon
                                            color="secondary"
                                        />
                                    </IconButton>
                                </TableCell>)}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </>

    )
})
